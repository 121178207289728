import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.css'],
})
export class CalculatorComponent implements OnInit {
  salePrice : string;
  tax : string ;
  retunProfit : string;
 
  constructor() { }

  ngOnInit() { }

  calculateTax(salePrice : string){
    var newSalePrice =(this.salePrice === null ||  this.salePrice === '/') 
                      ? Number(null) : Number(this.salePrice.split(",").join("").replace(/\,/g,'').replace(/[A-Za-z!@#$%^&*()]/g, ''));

    this.retunProfit = (this.CalculateReturn(newSalePrice,1)).toString();
    if(this.retunProfit === '0' || newSalePrice === 0){
      this.salePrice = null;
      this.retunProfit = null;
      this.tax = null;
    }
    else{
      this.tax = (newSalePrice - Number(this.retunProfit))
                .toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      this.retunProfit = this.retunProfit.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.salePrice = newSalePrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");  
    }
  }
  CalculateReturn(soldPrice : number, quantity : number) : number{
    if (soldPrice == 0)
      return 0;
    let profit = Number(Math.round(Number((soldPrice * 0.95))));
    return profit * quantity;  
  }
}



