import { HttpClient } from "@angular/common/http";
import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
  Output,
  EventEmitter, HostListener
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NzIconModule } from 'ng-zorro-antd/icon';

var dbConfig = require('src/lib/configLoader').databaseConfig;


@Component({
  selector: "app-player-search",
  templateUrl: "./player-search.component.html",
  styleUrls: ["../../app.component.css"],
})
export class PlayerSearchComponent implements OnInit {
  searchTerm = "";
  searching = false;
  playerList = [];
  @Input() minLength = 2;
  @Input() debounce = 600;
  @Input() isLoadCard: boolean
  @Input() isLoadImage: boolean
  @Input() addNewTrade: boolean

  atHome: boolean;
  atOther: boolean;
  onFocus: boolean;
  mouseEnter: boolean = false;

  @ViewChild("searchBar") searchBar: ElementRef;
  @ViewChild("searchList") searchList: ElementRef;
  
  @Output() onLoadCard : EventEmitter<any> = new EventEmitter<any>();
  @Output() onLoadImage : EventEmitter<any> = new EventEmitter<any>();
  isMobile: boolean;

  private url: string = dbConfig.apiurl;

    constructor(   
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2
  ) {
    this.renderer.listen("window", "click", (e: Event) => {
      if (
        e.target !== this.searchBar.nativeElement &&
        e.target !== this.searchList.nativeElement
      ) {
        this.onFocus = false;
      }
    });
  }
  public ngAfterViewInit() {
    this.detectScreenSize();
}
  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
   this.detectScreenSize()


  }
  detectScreenSize(){
    if ( window.innerWidth < 991 ) {
     this.isMobile = true
    
    }
    else{
      this.isMobile = false
     
    }
  }

  ngOnInit(): void {
    // if (this.router.url == "/playerjournals") {
    //   this.atHome = true;
    //   this.atOther = false;
    //   if(window.innerWidth < 991){
    //     this.atHome = false
    //     this.atOther = true
    //   }
    // } else {
    //   this.atHome = false;
    //   this.atOther = true;
    // }
  }

  hide(event: any) {
    if (this.mouseEnter == true) {
    
    } 
    else if(this.addNewTrade == true)
    {
      this.onFocus = false;

      this.mouseEnter = false;
    }
    else {
      // when click outside !!
      this.searchTerm= ""
      this.playerList = []
      this.onFocus = false;

      this.mouseEnter = false;
    }
  }

  show(event: any) {
    
    this.onFocus = true;
  }
  focus() {
  
    this.onFocus = true;
    this.mouseEnter = true;
  }
  focusOut() {
   
    this.onFocus = true;
    this.mouseEnter = false;
  }
  close(player: any) {

if(this.isLoadCard == true){
    this.onLoadCard.emit(player)
  }
 else if(this.isLoadImage == true){
    this.onLoadImage.emit(player)
  }
  else if(this.addNewTrade == true){
    this.searchTerm = player.PlayerFullName
    this.onLoadCard.emit(player)
    this.playerList = []
    this.onFocus = false;
    this.mouseEnter = false;
return
  }
  else{
   var playerShortName  = player.PlayerShortName
   var playerExternalId = player.PlayerExternalId
   var url = `${playerShortName}/${playerExternalId}`;
    
    this.router.navigateByUrl(url)
  
  }
    this.searchTerm = "";
this.playerList = []
    this.onFocus = false;
    this.mouseEnter = false;
  }
  search(): void {
    const placeholder = this.searchTerm;
   
    setTimeout(() => {
      if (
        placeholder === this.searchTerm &&
        this.searchTerm.length > this.minLength
      ) {
        this.searching = true;
        this.http
          .post(this.url + 'api/Player/SearchForPlayer', {
            Keyword: this.searchTerm,
          })
          .subscribe((res) => {
            console.log(res);
            this.searching = false;
            this.onFocus = true;
            this.mouseEnter = true;
            this.playerList = res["Results"];
            this.playerList.forEach((item) => {
              const a = item.PlayerCardTypeDisplayName;
              const card = a.toLowerCase();
              item.CardClass2 = "list-item eafc24_" + card.replace(/\s+/g, '') + "_gradient";
            });
          });
      } else if (
        placeholder === this.searchTerm &&
        this.searchTerm.length <= this.minLength
      ) {
        this.playerList = [];
        // this.onFocus = false;
        // this.mouseEnter = false
      }
    }, this.debounce);
  }
}
