
<!-- <div class='container-fluid'> -->
        <router-outlet></router-outlet>

    <!-- <pm-menu></pm-menu>                
    <div class="row">
        <div class="col-md-0 col-lg-0">
            
        </div>
        <div class="col-md-12 col-lg-12" style="min-height: 800px;">
            
            
            
        </div>
        <div class="col-md-0 col-lg-0">
            
        </div>
    </div> -->
<!-- </div>  -->
