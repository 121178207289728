import { NgModule } from '@angular/core';
import { RouterModule, Routes,ExtraOptions } from '@angular/router';
import { CalculatorComponent } from './calculator/calculator.component';
import { PageNotFoundComponent } from './home/page-not-found.component';
import { ShellComponent } from './home/shell.component';
import { WelcomeComponent } from './home/welcome.component';
import { TraderJournalsComponent } from './trader-journals/trader-journals.component';



const appRoutes: Routes = [
    {
        path: '',
        component: ShellComponent,
        children: [
            { path: 'welcome', component: WelcomeComponent },
            {
                path: 'products',
                // canActivate: [AuthGuard],
                loadChildren: () => import('./products/product.module').then(m => m.ProductModule)
            },
            {
                path: 'playerjournals',
                //canActivate: [AuthGuard],
                loadChildren: () => import('./playerjournals/playerjournal.module').then(m => m.PlayerjournalModule)
            },
            // { path: 'login', component: LoginComponent },
            // { path: 'signup', component: SignupComponent },                   
            { path: '', redirectTo: 'welcome', pathMatch: 'full' }
        ]
    },
    { path: 'calculator', component: CalculatorComponent },
    { path: 'trader-journals/:encryptedString', component: TraderJournalsComponent },
    { path: '**', component: PageNotFoundComponent }
];
@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, { urlUpdateStrategy: 'deferred' }) // , { enableTracing: true })
  // , { enableTracing: true })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }