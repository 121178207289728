<div class="container-fluid">
    <div class="row">
        <div class="col-md-7 mt-5 text-center justify-content-end d-flex"
            style="color: white; font-family:DIN Pro Bold; font-size: 30px; font-weight: 550;">
            The #1 Tools For
            <br>
            EA FC Ultimate Team Traders
        </div>
    </div>

    <div class="row mt-5">
        <div class="col-12 col-md-6">
            <div class="row justify-content-center">
                <div class="col-md-3 col-sm-6">
                    <!-- desktop logo -->
                    <div class="col-md-3 col-sm-12 mt-2 ml-3" *ngIf="!isMobile" [routerLink]="['/welcome']">
                        <img src="https://cdn.futalert.co.uk/Images/FCAlertLogo-WhiteBeta.png" alt="Fut alert logo" style="width: 12rem;">
                    </div>

                    <!-- mobile logo -->
                    <div class="col-md-3 col-sm-12 mt-2 ml-3 text-center" *ngIf="isMobile" [routerLink]="['/welcome']">
                        <img src="https://cdn.futalert.co.uk/Images/FCAlertLogo-WhiteBeta.png" alt="Fut alert logo" style="width: 12rem;">
                    </div>
                </div>
                <div class="col-12 col-md-12 ml-3 mb-3 text-center">
                    <img class="img-responsive mb-3"
                        src="https://corporate.futalert.co.uk/content/images/iphonex-new-small.png"
                        style="width: 100%; max-width: 12rem; height: auto;" alt="screens">
                    <img class="img-responsive" src="https://cdn.futalert.co.uk/Images/flipping-list-iphone.png"
                        style="width: 100%; max-width: 14rem; height: auto;" alt="screens">
                </div>
                <div class="col-12 text-center mt-3 mb-3">
                    <h4 class="text-white">ON MOBILE</h4>
                    <div class="d-flex justify-content-center">
                        <a type="button" target='_blank' href='https://itunes.apple.com/us/app/futalert/id1358652393'
                            class="btn btn-default btn-fut"
                            style="width:160px; background: transparent; border-color: transparent;"><img
                                src="/assets/icons/download_apple.png" style="width: 100%; max-width: 150px;"></a>
                        <a type="button" target='_blank'
                            href='https://play.google.com/store/apps/details?id=uk.co.elcsoft.futalert'
                            class="btn btn-success btn-fut"
                            style="width:160px;background: transparent; border-color: transparent;"><img
                                src="/assets/icons/download_google.png" style="width: 100%; max-width: 150px;"></a>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-6">
                        <h2 class="text-center text-white">My Portfolio</h2>
                        <form class="form" novalidate (ngSubmit)="signup(signupForm)" #signupForm="ngForm"
                            autocomplete="off">
                            <fieldset>
                                <div class="form-group"
                                    [ngClass]="{'has-error': (fullNameVar.touched || fullNameVar.dirty) && !fullNameVar.valid }">
                                    <label class="control-label" for="fullNameId">Full Name</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i style="font-size: 1.15rem;"
                                                    class="fas fa-file-signature"></i></span>
                                        </div>
                                        <input class="form-control" id="fullNameId" type="text"
                                            placeholder="Full Name (required)" required ngModel name="fullName"
                                            #fullNameVar="ngModel" />
                                    </div>
                                    <div style="display: block; color: #dc3545; font-size: 14px; margin-top: 5px;"
                                        *ngIf="(fullNameVar.touched ||
                                    fullNameVar.dirty) &&
                                    fullNameVar.errors">
                                        <span *ngIf="fullNameVar.errors.required">Full name is required.</span>
                                    </div>
                                    <div style="display: block; color: #dc3545; font-size: 14px; margin-top: 5px;"
                                        *ngIf="(fullNameVar.touched ||
                                    fullNameVar.dirty) &&
                                    fullNameVar.errors">
                                        <span *ngIf="fullNameVar.errors.incorrect">Full name nust be minimum two
                                            words.</span>
                                    </div>
                                </div>
                                <div class="form-group" [ngClass]="{'has-error': (emailVar.touched || 
                                                                    emailVar.dirty) && 
                                                                    !emailVar.valid }">
                                    <label class="control-label" for="emailId">Email</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="fas fa-at"></i></span>
                                        </div>
                                        <input class="form-control" id="emailId" type="text"
                                            placeholder="Email (required)" required ngModel name="email"
                                            #emailVar="ngModel" />
                                    </div>
                                    <div style="display: block; color: #dc3545; font-size: 14px; margin-top: 5px;"
                                        *ngIf="(emailVar.touched || emailVar.dirty) && emailVar.errors">
                                        <span *ngIf="emailVar.errors.required">Email is required.</span>
                                    </div>
                                    <div style="display: block; color: #dc3545; font-size: 14px; margin-top: 5px;"
                                        *ngIf="(emailVar.touched || emailVar.dirty) && emailVar.errors">
                                        <span *ngIf="emailVar.errors.incorrect">Email is invalid.</span>
                                    </div>
                                </div>
                                <div class="form-group" [ngClass]="{'has-error': (userNameVar.touched || 
                                                            userNameVar.dirty) && 
                                                            !userNameVar.valid }">
                                    <label class="control-label" for="userNameId">User Name</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="fas fa-user"></i></span>
                                        </div>
                                        <input class="form-control" id="userNameId" type="text"
                                            placeholder="User Name (required)" required ngModel name="userName"
                                            #userNameVar="ngModel" />
                                    </div>
                                    <div style="display: block; color: #dc3545; font-size: 14px; margin-top: 5px;"
                                        *ngIf="(userNameVar.touched ||
                                    userNameVar.dirty) &&
                                    userNameVar.errors">
                                        <span *ngIf="userNameVar.errors.required">User name is required.</span>
                                    </div>
                                    <div style="display: block; color: #dc3545; font-size: 14px; margin-top: 5px;"
                                        *ngIf="(userNameVar.touched ||
                                userNameVar.dirty) &&
                                userNameVar.errors">
                                        <span *ngIf="userNameVar.errors.incorrect">User name is invalid.</span>
                                    </div>
                                </div>
                                <div class="form-group" [ngClass]="{'has-error': (passwordVar.touched || 
                                                            passwordVar.dirty) && 
                                                            !passwordVar.valid }">
                                    <label class=" control-label" for="passwordId">Password</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="fas fa-lock"></i></span>
                                        </div>
                                        <input class="form-control" id="passwordId" type="password"
                                            placeholder="Password (required)" required ngModel name="password"
                                            #passwordVar="ngModel" />
                                    </div>
                                    <div style="display: block; color: #dc3545; font-size: 14px; margin-top: 5px;"
                                        *ngIf="(passwordVar.touched || passwordVar.dirty) && passwordVar.errors">
                                        <span *ngIf="passwordVar.errors.required">Password is required.</span>
                                    </div>
                                    <div style="display: block; color: #dc3545; font-size: 14px; margin-top: 5px;"
                                        *ngIf="(passwordVar.touched || passwordVar.dirty) && passwordVar.errors">
                                        <span *ngIf="passwordVar.errors.incorrect">Password is invalid.</span>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class=" control-label" for="preferredPlatformId">Preferred Platform</label>
                                    <div class="input-group">
                                        <div class="dropdown" style="width: 100%;">
                                            <button class="btn btn-default dropdown-toggle"
                                                style="width: 100%; text-align: left" type="button" id="dropdownMenu1"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                {{PreferredPlatformTitle}}
                                            </button>
                                            <ul name="preferredPlatformSelect" class="dropdown-menu"
                                                aria-labelledby="dropdownMenu1" style="width: 100%;" ngModel
                                                #preferredPlatformSelectVar="ngModel">
                                                <li value="1" (click)="preferredPlatformSelect(1,signupForm)"><a
                                                        style="margin-left: 1em; cursor: pointer;">PS4</a></li>
                                                <li value="2" (click)="preferredPlatformSelect(2,signupForm)"><a
                                                        style="margin-left: 1em; cursor: pointer;">XBox</a></li>
                                            </ul>
                                        </div>
                                        <span class="help-block" *ngIf="(preferredPlatformSelectVar.touched ||
                                                                        preferredPlatformSelectVar.dirty) &&
                                                                        preferredPlatformSelectVar.errors">
                                            <span *ngIf="preferredPlatformSelectVar.errors.required">
                                                Preferred PlatformSelect is required.
                                            </span>
                                        </span>
                                        <span class="help-block help-block-incorrect"
                                            *ngIf="preferredPlatformSelectVar.errors">
                                            <span *ngIf="preferredPlatformSelectVar.errors.incorrect">
                                                Preferred PlatformSelect is invalid.
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row" style="display: contents;">
                                        <div class="col-xs-12 d-flex justify-content-end" style="text-align: center">
                                            <button class="btn btn-primary col-xs-6 form-control" type="submit"
                                                [disabled]="!signupForm.valid">
                                                Sign Up
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div style="text-align: center;margin: 50px 0px 50px 0px;" *ngIf="IsApiCallProgress">
                                    <br>
                                    <p-progressSpinner [style]="{width: '50px', height: '50px'}"
                                        style="margin-top: 10px; margin-bottom: 10px" strokeWidth="8" fill="#EEEEEE"
                                        animationDuration=".5s"></p-progressSpinner>
                                </div>
                                <div class="col-xs-12 d-flex justify-content-between">
                                    <div class="col-xs-6">
                                        <a routerLink="/login" style="color: #0068ff; cursor: pointer;">Login</a>
                                    </div>
                                    <div class="col-xs-6 text-right">
                                        <a routerLink="/forgotpassword" style="color: #0068ff; cursor: pointer;">Forgot
                                            my password</a>
                                    </div>
                                </div>

                                <div class="col-xs-12">
                                    <div class="row">
                                        <div class="col-xs-12" style="text-align: center;">
                                            <div class="has-error-api">{{errorMessage}}</div>
                                        </div>
                                        <div style="clear: both;"></div>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- desktop alert -->
<div class="d-sm-none d-md-block" style="position: fixed; bottom: 0; width: 45%; padding: 15px;" *ngIf="!isMobile">
    <div class="alert alert-danger" role="alert" *ngIf="isError">
        {{errorMessage}}
        <button type="button" class="close" (click)="isError = false">&times;</button>
    </div>
</div>

<!-- mobile alert -->
<div class="d-sm-none d-md-block" style="position: fixed; bottom: 0; width: 100%;" *ngIf="isMobile">
    <div class="alert alert-danger" role="alert" *ngIf="isError">
        {{errorMessage}}
        <button type="button" class="close" (click)="isError = false">&times;</button>
    </div>
</div>

<footer class="footer-n mobile-footer">
    <div class="col-12 col-sm-12">
        <div class="text--center">
            <!-- FUT Alert Adsense -->
            <ins class="adsbygoogle" style="display:inline-block;width:728px;height:90px"
                data-ad-client="ca-pub-8212701613856478" data-ad-slot="3040602989"></ins>
            <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
            </script>
            <br><br>
            <span>© 2020 FUT Alert, developed by&nbsp;</span> <a target="_blank"
                href="http://www.elcsoft.co.uk">elcsoft.co.uk</a> | <a target="_blank"
                href="http://www.futalert.co.uk/privacypolicy">Privacy Policy</a> | <a target="_blank"
                href="http://www.futalert.co.uk/FAQ">Help &amp; FAQ</a> | <a target="_blank"
                href="https://twitter.com/fut_alert">Support</a> | Contact Us: <a
                href="mailto:support@FUTAlert.co.uk">support@futalert.co.uk</a>
            <br><br>
            <p>All player, team and league related images / logos are property of&nbsp;EA Sports</p>
        </div>
    </div>
</footer>