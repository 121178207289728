<div class='row'>
  <div class='col-md-2'>Filter by:</div>
  <div class='col-md-4'>
      <input type='text' #filterElement
             [(ngModel)]='listFilter' />
  </div>
</div>
<div class='row' *ngIf='displayDetail'>
  <div class='col-md-10'>
      <h3>Filtered by: {{listFilter}} 
          {{hitMessage}}
      </h3>
  </div>
</div>
