<nav class="navbar navbar-default"
     style="background-color: black; border: none;">
    <div class="container-fluid"
         style="margin-top: 10px;">
        <div class="navbar-header">
            <button type="button"
                    class="navbar-toggle collapsed"
                    data-toggle="collapse"
                    data-target="#bs-navbar-collapse-fut"
                    aria-expanded="false">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <a class="navbar-brand menucolour"></a>
            <img src="/assets/icons/FUT_logo.png"
                 style="max-width:70px;max-height:60px;padding-top: 0px;padding-bottom: 10px; " />
            <a id="small_fut_text"
               style="font-size:xx-large ;margin-left:5px;color: #fff;display: inline-block; margin-left: 1em; text-transform: uppercase; font-weight: bold; font-family: DIN Pro Condensed Bold;">{{ pageTitle }}</a>
        </div>

    </div>
</nav>
<div style="text-align: center;position: absolute; left: 50%; top:40%"
     *ngIf="!!loading">
    <br>
    <p-progressSpinner [style]="{width: '50px', height: '50px'}"
                       style="margin-top: 10px; margin-bottom: 10px"
                       strokeWidth="8"
                       fill="#EEEEEE"
                       animationDuration=".5s"></p-progressSpinner>
</div>
<ng-container *ngIf="!loading">
    <div class="col-xs-11 col-sm-4 col-md-6 col-lg-5 portfolio_div"
         style="margin-bottom:25px;">
        <div class="row"
             class="portfolio_container">
            <div style="display: flex; justify-content:center; align-items:center;">
                <div class="col-xs-2 portfolio_img_container"
                     style="flex:1">
                    <a href="https://www.patreon.com/futinvest101"
                       target="_blank"
                       title="FUT Trading 101's Twitter Account"><img class="portfolio_img"
                             src="https://cdn.futalert.co.uk/Images/FT101_200x200.jpg" /></a>
                </div>
                <div *ngIf="total > 0"
                     class="col-xs-2 portfolio_profit_title">CURRENT PROFIT:</div>
                <div *ngIf="total > 0"
                     class="col-xs-2 portfolio_profit row-height">
                    <!-- min-height: 4.2em; min-width: 7.5em; -->
                    <h2 class="portfolio_profit_h2">
                        {{currentTotalProfit}}
                    </h2>
                </div>
                <div *ngIf="total <= 0"
                     class="col-xs-2 portfolio_profit_title">CURRENT LOSS:</div>
                <div *ngIf="total <= 0"
                     class="col-xs-2 portfolio_loss row-height"
                     style="padding-right: 1em">
                    <h2 class="portfolio_loss_h2">
                        {{currentTotalProfit}}
                    </h2>
                </div>
            </div>
            <div style="clear: both;"></div>
        </div>
    </div>

    <p-table [style.display]="'block'"
             [value]="playerJournalDTOs"
             class="p-table"
             [responsive]="false"
             selectionMode="single"
             [paginator]="true"
             [rowsPerPageOptions]="pagingNumbers"
             [rows]="pagingNumberSelected">
        <ng-template pTemplate="header">
            <tr class="borderwith ui-g">
                <th class="ui-g-1"
                    style="text-align: center;">
                    <p class="glyphicon glyphicon-time"></p>
                    <a *ngIf="sortIconsCurrentcss === 'pi-sort' || (sortIconsCurrentcss != 'pi-sort-up-createdDate' && sortIconsCurrentcss != 'pi-sort-down-createdDate')"
                       class="ui-table-sort-icon"
                       style="color:#fff;cursor: pointer;"
                       aria-label="Activate to sort in descending order"><i (click)="sort('date')"
                           class="ui-sortable-column-icon pi pi-fw pi-sort"></i></a>
                    <a *ngIf="sortIconsCurrentcss === 'pi-sort-up-createdDate'"
                       class="ui-table-sort-icon"
                       style="color:#fff;cursor: pointer;"
                       aria-label="Activate to sort in descending order"><i (click)="sort('date')"
                           class="ui-sortable-column-icon pi pi-fw pi-sort-up"></i></a>
                    <a *ngIf="sortIconsCurrentcss === 'pi-sort-down-createdDate'"
                       class="ui-table-sort-icon"
                       style="color:#fff;cursor: pointer;"
                       aria-label="Activate to sort in descending order"><i (click)="sort('date')"
                           class="ui-sortable-column-icon pi pi-fw pi-sort-down"></i></a>

                    <p>Date Bought</p>
                </th>
                <th class="ui-g-2">
                    <img src="/assets/icons/player.png"
                         style="width: 30px;height: 30px;" />
                    <a *ngIf="sortIconsCurrentcss === 'pi-sort' || (sortIconsCurrentcss != 'pi-sort-up-playerFullName' && sortIconsCurrentcss != 'pi-sort-down-playerFullName')"
                       class="ui-table-sort-icon"
                       style="color:#fff;cursor: pointer;"
                       aria-label="Activate to sort in descending order"><i (click)="sort('fullName')"
                           class="ui-sortable-column-icon pi pi-fw pi-sort"></i></a>
                    <a *ngIf="sortIconsCurrentcss === 'pi-sort-up-playerFullName'"
                       class="ui-table-sort-icon"
                       style="color:#fff;cursor: pointer;"
                       aria-label="Activate to sort in descending order"><i (click)="sort('fullName')"
                           class="ui-sortable-column-icon pi pi-fw pi-sort-up"></i></a>
                    <a *ngIf="sortIconsCurrentcss === 'pi-sort-down-playerFullName'"
                       class="ui-table-sort-icon"
                       style="color:#fff;cursor: pointer;"
                       aria-label="Activate to sort in descending order"><i (click)="sort('fullName')"
                           class="ui-sortable-column-icon pi pi-fw pi-sort-down"></i></a>
                    <!-- <input pInputText type="text" (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)"> -->

                    <p>Player Information</p>
                </th>
                <th class="ui-g-2">
                    <img src="/assets/icons/bought.png"
                         style="width: 30px;height: 30px;" />
                    <a *ngIf="sortIconsCurrentcss === 'pi-sort' || (sortIconsCurrentcss != 'pi-sort-up-totalInvestment' && sortIconsCurrentcss != 'pi-sort-down-totalInvestment')"
                       class="ui-table-sort-icon"
                       style="color:#fff;cursor: pointer;"
                       aria-label="Activate to sort in descending order"><i (click)="sort('boughtPrice')"
                           class="ui-sortable-column-icon pi pi-fw pi-sort"></i></a>
                    <a *ngIf="sortIconsCurrentcss === 'pi-sort-up-totalInvestment'"
                       class="ui-table-sort-icon"
                       style="color:#fff;cursor: pointer;"
                       aria-label="Activate to sort in descending order"><i (click)="sort('boughtPrice')"
                           class="ui-sortable-column-icon pi pi-fw pi-sort-up"></i></a>
                    <a *ngIf="sortIconsCurrentcss === 'pi-sort-down-totalInvestment'"
                       class="ui-table-sort-icon"
                       style="color:#fff;cursor: pointer;"
                       aria-label="Activate to sort in descending order"><i (click)="sort('boughtPrice')"
                           class="ui-sortable-column-icon pi pi-fw pi-sort-down"></i></a>

                    <p>Bought</p>
                </th>
                <th class="ui-g-2 currentprice_div">
                    <img src="/assets/icons/currentprice.png"
                         style="width: 30px;height: 30px;" />
                    <a *ngIf="sortIconsCurrentcss === 'pi-sort' || (sortIconsCurrentcss != 'pi-sort-up-currentPrice' && sortIconsCurrentcss != 'pi-sort-down-currentPrice')"
                       class="ui-table-sort-icon"
                       style="color:#fff;cursor: pointer;"
                       aria-label="Activate to sort in descending order"><i (click)="sort('currentPrice')"
                           class="ui-sortable-column-icon pi pi-fw pi-sort"></i></a>
                    <a *ngIf="sortIconsCurrentcss === 'pi-sort-up-currentPrice'"
                       class="ui-table-sort-icon"
                       style="color:#fff;cursor: pointer;"
                       aria-label="Activate to sort in descending order"><i (click)="sort('currentPrice')"
                           class="ui-sortable-column-icon pi pi-fw pi-sort-up"></i></a>
                    <a *ngIf="sortIconsCurrentcss === 'pi-sort-down-currentPrice'"
                       class="ui-table-sort-icon"
                       style="color:#fff;cursor: pointer;"
                       aria-label="Activate to sort in descending order"><i (click)="sort('currentPrice')"
                           class="ui-sortable-column-icon pi pi-fw pi-sort-down"></i></a>

                    <p>Current Price</p>
                </th>
                <th class="ui-g-2">
                    <img src="/assets/icons/currentprogress.png"
                         style="width: 30px;height: 30px;" />
                    <a *ngIf="sortIconsCurrentcss === 'pi-sort' || (sortIconsCurrentcss != 'pi-sort-up-totalInvestmentPL' && sortIconsCurrentcss != 'pi-sort-down-totalInvestmentPL')"
                       class="ui-table-sort-icon"
                       style="color:#fff;cursor: pointer;"
                       aria-label="Activate to sort in descending order"><i (click)="sort('totalInvestmentPL')"
                           class="ui-sortable-column-icon pi pi-fw pi-sort"></i></a>
                    <a *ngIf="sortIconsCurrentcss === 'pi-sort-up-totalInvestmentPL'"
                       class="ui-table-sort-icon"
                       style="color:#fff;cursor: pointer;"
                       aria-label="Activate to sort in descending order"><i (click)="sort('totalInvestmentPL')"
                           class="ui-sortable-column-icon pi pi-fw pi-sort-up"></i></a>
                    <a *ngIf="sortIconsCurrentcss === 'pi-sort-down-totalInvestmentPL'"
                       class="ui-table-sort-icon"
                       style="color:#fff;cursor: pointer;"
                       aria-label="Activate to sort in descending order"><i (click)="sort('totalInvestmentPL')"
                           class="ui-sortable-column-icon pi pi-fw pi-sort-down"></i></a>

                    <p>Current Profit/Loss</p>
                </th>
                <th class="ui-g-2">
                    <p class="glyphicon glyphicon-list-alt"></p>
                    <p>Description</p>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body"
                     let-rowData
                     let-playerJournalDTO>
            <tr class="borderwith ui-g"
                [ngClass]="playerJournalDTO.isModified ? playerJournalDTO.trendingcss : null">
                <td class="ui-g-1 vertical-align"
                    style="text-align: center; ">
            <tr class="ui-g">
                <td class="ui-g-12"
                    style="padding: 0.2em">
                    <!-- TODO: implement this on all items-->
                    <!-- <span style="font-weight: bold; margin: 0px;" [class]="(playerJournalDTO.trendingcss === 'trending_up') ? 'trending_up_after_row' : ''">{{playerJournalDTO.journalItem.createdDate | date:"MMM"}} {{playerJournalDTO.journalItem.createdDate | date:"dd"}}</span>-->
                    <span
                          style="font-weight: bold; margin: 0px;">{{playerJournalDTO.journalItem.createdDate | date:"MMM"}}
                        {{playerJournalDTO.journalItem.createdDate | date:"dd"}}</span>
                </td>
                <td class="ui-g-12"
                    style="padding: 0.2em">
                    <span
                          style="font-weight: bold;; margin: 0px; font-size: 1.2em">{{playerJournalDTO.journalItem.createdDate | date:"HH:mm" }}</span>
                </td>
            </tr>
            </td>
            <td class="ui-g-2">
                <tr class="ui-g">
                    <td class="ui-xs-6 ui-lg-4"
                        style="padding: 0px;"><img [src]="playerJournalDTO.playerDetail.facePhotoUrl"
                             style="width: 100%; margin-right: 10px" /></td>
                    <td class="ui-xs-6 ui-lg-8"
                        style="padding: 0px; padding-top: 15px;">
                        <p
                           style="text-transform: uppercase; font-weight: bold; font-family: DIN Pro Condensed Bold; font-size: 0.9em">
                            {{playerJournalDTO.playerDetail.playerFullName}}</p>
                <tr class="ui-g"
                    style="padding: 0px;">
                    <td class="ui-xs-4 ui-lg-4"
                        style="padding: 0px;">
                        <img [src]="playerJournalDTO.playerDetail.badgePhotoUrl"
                             style="width: 34px;height: 30px;" />
                    </td>
                    <td class="ui-xs-4 ui-lg-4"
                        style="padding: 0px;">
                        <img [src]="playerJournalDTO.playerDetail.nationPhotoUrl"
                             style="width: 43px;height: 30px;" />
                    </td>
                    <td class="ui-xs-4 ui-lg-4"
                        style="padding-left: 11px; padding-top: 0px; margin-top: -5px">
                        <div class="container1"
                             style="position: relative; text-align: center; display: inline-block; width: 30px;height: 40px;">
                            <img src="https://cdn.futalert.co.uk{{playerJournalDTO.playerCardTypeImg}}"
                                 style="width:100%;height:40px;" />
                            <div class="centered1"
                                 [style.color]="playerJournalDTO.overallTextColor"
                                 style="font-family: DIN Pro Medium; font-size: medium; position: absolute; top: 48%; left: 50%; transform: translate(-50%, -50%);">
                                {{playerJournalDTO.playerDetail.overall}} </div>
                        </div>
                    </td>
                </tr>
            </td>
            </tr>
            </td>
            <td class="ui-g-2">
                <span style="font-family: DIN Pro; display: block; text-align: center"
                      *ngIf="playerJournalDTO.journalItem.quantity === 1">{{playerJournalDTO.journalItem.quantity}} Card
                    at</span>
                <span style="font-family: DIN Pro; display: block; text-align: center;"
                      *ngIf="playerJournalDTO.journalItem.quantity != 1">{{playerJournalDTO.journalItem.quantity}} Cards
                    at</span>
                <div style="display: flex;justify-content: center;">
                    <span *ngIf="playerJournalDTO.journalItem.boughtPrice != 0"
                          style="text-transform: uppercase; font-weight: bold; font-family: DIN Pro Condensed Bold; text-align: center">{{ commaSeparated(playerJournalDTO.journalItem.boughtPrice) }}</span>
                    <span *ngIf="playerJournalDTO.journalItem.boughtPrice === 0"
                          style="text-transform: uppercase; font-weight: bold; font-family: DIN Pro Condensed Bold; text-align: center;display: block">Packed</span>
                    <img *ngIf="playerJournalDTO.journalItem.boughtPrice != 0"
                         src="/assets/icons/fut.png"
                         style="margin-left: 5px; width: 30px;height: 30px; text-align: center; display: block;" />
                </div>
                <span style="font-family: DIN Pro; display: block; text-align: center;">Total Investment:</span>
                <span *ngIf="playerJournalDTO.totalInvestment != '00'"
                      style="font-weight: bold; font-family: DIN Pro Condensed Bold; display: block; text-align: center">{{playerJournalDTO.totalInvestment}}</span>
                <span *ngIf="playerJournalDTO.totalInvestment === '00'"
                      style="text-transform: uppercase; font-weight: bold; font-family: DIN Pro Condensed Bold; display: block;text-align: center;">Packed</span>

            </td>
            <td class="ui-g-2 currentprice_div vertical-align"
                style="text-align: center;">
                <tr class="ui-g">
                    <td class="ui-g-12"
                        style="font-weight: bold;">
                        <div style="display: flex; align-items: center; justify-content: center"
                             *ngIf="playerJournalDTO.playerDetail.currentPricePS4>0">
                            <span class="currentprice_span"
                                  style="margin-right: 15px;">{{ commaSeparated(playerJournalDTO.playerDetail.currentPricePS4) }}</span><img
                                 style="max-width:40px;max-height:22px;"
                                 src="https://cdn.futalert.co.uk/Images/platforms/ps4.png">
                        </div>
                        <div style="display: flex; align-items: center; justify-content: center"
                             *ngIf="playerJournalDTO.playerDetail.currentPriceXBox>0">
                            <span class="currentprice_span"
                                  style="margin-right: 15px;">{{ commaSeparated(playerJournalDTO.playerDetail.currentPriceXBox) }}</span><img
                                 _ngcontent-c3=""
                                 style="max-width:40px;max-height:22px;"
                                 src="https://cdn.futalert.co.uk/Images/platforms/xbox.png">
                        </div>
                        <div style="display: flex; align-items: center; justify-content: center"
                             *ngIf="playerJournalDTO.playerDetail.currentPricePS4<1 && playerJournalDTO.playerDetail.currentPriceXBox<1">
                            <span
                                  style="font-family: DIN Pro; display: block; font-size: larger; text-align: center;position: absolute; left:55%;">-</span>
                        </div>
                        <i *ngIf="playerJournalDTO.trendingcss === 'trending_up'"
                           [class]="(playerJournalDTO.trendingcss === 'trending_up') ? 'fa fa-chevron-up trending_up_after' : 'fa fa-chevron-up'"
                           aria-hidden="true"> </i>
                        <i *ngIf="playerJournalDTO.trendingcss === 'trending_down'"
                           [class]="(playerJournalDTO.trendingcss === 'trending_down') ? 'fa fa-chevron-down trending_down_after' : 'fa fa-chevron-down'"
                           aria-hidden="true"> </i>
                    </td>
                    <td class="ui-g-11"
                        *ngIf="playerJournalDTO.playerDetail.currentPriceXBox>0 && playerJournalDTO.playerDetail.currentPricePS4>0">
                        <span class="currentprice_lastupdated">Updated: {{playerJournalDTO.lastUpdatedDateValue}}</span>
                    </td>
                </tr>
            </td>
            <td
                [class]="playerJournalDTO.profitLossPerCard != null && playerJournalDTO.profitLossPerCard.trim() == '00' ? 'ui-g-2 vertical-align' : 'ui-g-2'">
                <tbody *ngIf="playerJournalDTO.profitLossPerCard != null && playerJournalDTO.profitLossPerCard.trim() == '00'"
                       style="text-align: center; width:100%;">
                    <tr class="ui-g">
                        <td class="ui-g-12"
                            style="padding: 0.2em;">
                            <span
                                  style="font-family: DIN Pro; display: block; font-size: larger; text-align: center;">-</span>
                        </td>
                    </tr>
                </tbody>
                <tr class="ui-g">
                    <td class="ui-g-12"
                        *ngIf="!(playerJournalDTO.profitLossPerCard != null && playerJournalDTO.profitLossPerCard.trim() == '00')">
                        <span *ngIf="playerJournalDTO.profitLossPerCard != null && playerJournalDTO.profitLossPerCard.trim() != '00'"
                              style="font-family: DIN Pro; display: block;">P/L per Card:</span>
                        <span style="text-transform: uppercase; font-weight: bold; font-family: DIN Pro Condensed Bold;"
                              [style.color]="ConvertString(playerJournalDTO.profitLossPerCard) > 0 ? 'green' : 'red'">
                            {{playerJournalDTO.profitLossPerCard != null && playerJournalDTO.profitLossPerCard.trim() != '00' ? playerJournalDTO.profitLossPerCard : null}}
                        </span>
                        <img *ngIf="playerJournalDTO.profitLossPerCard != null && playerJournalDTO.profitLossPerCard.trim() != '00'"
                             src="/assets/icons/fut.png"
                             style="width: 30px;height: 30px; margin-left: 5px" />
                        <span *ngIf="playerJournalDTO.profitLossPerCard != null && playerJournalDTO.profitLossPerCard.trim() != '00'"
                              style="font-family: DIN Pro; display: block;">Total Investment P/L:</span>
                        <span style="font-weight: bold; font-family: DIN Pro Condensed Bold;display: block;"
                              [style.color]="ConvertString(playerJournalDTO.totalInvestmentPL) > 0 ? 'green' : 'red'">{{playerJournalDTO.totalInvestmentPL != null && playerJournalDTO.totalInvestmentPL.trim() != '00' ? playerJournalDTO.totalInvestmentPL : null}}</span>
                    </td>
                </tr>
            </td>
            <td style="display: flex; align-items:center; justify-content:center"
                [class]="!playerJournalDTO.journalItem.soldPrice ? 'ui-g-2 vertical-align' : 'ui-g-2'">
                <tbody *ngIf="!playerJournalDTO.journalItem.soldPrice && !playerJournalDTO.journalItem.description"
                       style="text-align: center; width:100%;">
                    <tr class="ui-g">
                        <td class="ui-g-12"
                            style="padding: 0.2em;">
                            <span
                                  style="font-family: DIN Pro; display: block; font-size: larger; text-align: center;">-</span>
                        </td>
                    </tr>
                </tbody>
                <tr class="ui-g">
                    <div style="display: flex; justify-content:center;align-items:center; flex-direction: column">
                        <td class="ui-g-12"
                            *ngIf="!!playerJournalDTO.journalItem.soldPrice"
                            style="padding:0 0.2em 0 0;text-align: center;">
                            <span style="font-family: DIN Pro; display: block">Target Sell Price:</span>
                        </td>
                        <td class="ui-g-12"
                            *ngIf="!!playerJournalDTO.journalItem.soldPrice"
                            style="padding: 0 0.2em 0.2em 0; text-align: center">
                            <span
                                  style="text-transform: uppercase; font-weight: bold; font-family: DIN Pro Condensed Bold;">{{playerJournalDTO.journalItem.soldPrice}}</span>
                            <img src="/assets/icons/fut.png"
                                 style="width: 30px;height: 30px; margin-left: 5px" />
                        </td>
                    </div>
                    <td class="ui-g-12"
                        style="padding: 0.2em">
                        <span
                              style="font-family: DIN Pro;font-size: 0.9em; display: block;">{{playerJournalDTO.journalItem.description}}</span>
                    </td>
                </tr>
            </td>
            </tr>
        </ng-template>
    </p-table>
</ng-container>