<div class="header-search-form header-search-form--right"  style="width: 440px;padding: 1px 0px 0px 0px;" >
  <div class="search-form" style="display: flex;align-items: center;" >
    <input type="text"
           placeholder="{{ 'Search for a Player...' }}"
           class="form-control header-mobile__search-control"
           [(ngModel)]="searchTerm"
           (ngModelChange)="search()" (focusout)="hide($event)"   (focus)="show($event)"  (mouseenter)="focus()"  #searchBar style="background-color: #282840;border-color: #282840;padding-left: 20px;border-radius: 0;"/>
           <div class="header-mobile__search-submit" style="text-align:center;font-size:22px;line-height: 30px;border-radius: 0;">
    <i *ngIf="!searching"
       nz-icon
       [type]="'search'"
       [theme]="'outline'"
       class="search-icon"></i>
    <ng-template #indicatorTemplate>
     
      <i nz-icon
         [type]="'loading-3-quarters'"
         [spin]="'true'"
         class="loading-icon"></i>
    </ng-template>
    <nz-spin *ngIf="searching"
             nzSimple
             [nzIndicator]="indicatorTemplate"></nz-spin>
            </div>
  </div>
  
  <!-- <div class="header-search-form header-search-form--right">
    <form action="#" id="mobile-search-form" class="search-form">
      <input type="text" class="form-control header-mobile__search-control" value="" placeholder="Enter your search here...">
      <button type="submit" class="header-mobile__search-submit"><i class="fas fa-search"></i></button>
    </form>
  </div> -->
  <div [class.show]="searchTerm.length > minLength && playerList.length"
       class="list-wrapper" style="position: sticky!important;z-index: 10000;width: 440px;" [ngClass]=" onFocus ? 'show' : 'hide'" (mouseenter)="focus()" (mouseleave)="focusOut()" #searchList >
    <div *ngFor="let player of playerList"
         class="{{player.CardClass2}}" (click)="close(player)" >
      <div class="item-left">
      
        <div class="player-photo">
          <img [src]="player.FacePhotoUrl" />
        </div>
        <img [src]="player.BadgePhotoUrl"
             class="player-club" />
        <img [src]="player.NationPhotoUrl"
             class="player-nation" />
        <span class="player-name">
          <span class="title">
            {{ player.PlayerFullName }}
          </span>
          <span class="description">
            {{ player.CurrentPricePS4 | number }} 
          </span>
        </span>
        <span>
        <i nz-icon
           nzType="rise"
           nzTheme="outline"
           class="trade-status success"></i>
          </span>
      </div>
      <span class="player-rating">
        <span class="rating">
          {{ player.Overall }}
        </span>
        <span class="title">
          {{ player.PlayerCardTypeDisplayName }}
        </span>
      </span>

    </div>
    <!-- <div class="list-item bg-gold-rare">
      <div class="item-left">
        <div class="player-photo">
          <img src="https://cdn.futbin.com/content/fifa20/img/players/158023.png?v=22" />
        </div>
        <img src="https://cdn.futbin.com/content/fifa20/img/clubs/241.png"
             class="player-club" />
        <img src="https://cdn.futbin.com/content/fifa20/img/nation/52.png"
             class="player-nation" />
        <span class="player-name">
          <span class="title">
            Lionel Messi
          </span>
          <span class="description">
            1,502,000 - 5 mins ago
          </span>
        </span>
        <i nz-icon
           nzType="fall"
           nzTheme="outline"
           class="trade-status danger"></i>
      </div>
      <span class="player-rating">
        <span class="rating">
          95
        </span>
        <span class="title">
          Gold Rare
        </span>
      </span>
    </div> -->
  </div>
</div>




<div *ngIf="atHome" class="header-search-form header-search-form--right"  style="z-index: 3;width: 100%;padding: 0;">
  <div class="search-form" style="display: flex;align-items: center;">
    <input type="text"
           placeholder="{{ 'Search for a Player...'  }}"
           class="form-control header-mobile__search-control"
                      [(ngModel)]="searchTerm"
           (ngModelChange)="search()" (focusout)="hide($event)"   (focus)="show($event)" #searchBar style="background-color: #282840;border-color: #282840;padding-left: 20px;border-radius: 0;"/>
           <div class="header-mobile__search-submit" style="text-align:center;font-size:22px;line-height: 30px;border-radius: 0;">

           <i *ngIf="!searching"
       nz-icon
       nzType="search"
       nzTheme="outline"
       class="search-icon"></i>
    <!-- <ng-template #indicatorTemplate>
      <i nz-icon
         nzType="loading-3-quarters"
         [nzSpin]="true"
         class="loading-icon"></i>
    </ng-template>
    <nz-spin *ngIf="searching"
             nzSimple
             [nzIndicator]="indicatorTemplate"></nz-spin> -->
           </div>
  </div>
  <div [class.show]="searchTerm.length > minLength && playerList.length"
       class="list-wrapper" style="position: sticky!important;z-index: 10000;" [ngClass]=" onFocus ? 'show' : 'hide'" (mouseenter)="focus()" (mouseleave)="focusOut()" #searchList>
    <div *ngFor="let player of playerList"
         class="{{player.CardClass2}}" (click)="close(player)">
      <div class="item-left">
      
        <div class="player-photo">
          <img [src]="player.FacePhotoUrl" />
        </div>
        <img [src]="player.BadgePhotoUrl"
             class="player-club" />
        <img [src]="player.NationPhotoUrl"
             class="player-nation" />
        <span class="player-name">
          <span class="title">
            {{ player.PlayerFullName }}
          </span>
          <span class="description">
            {{ player.CurrentPricePS4 | number }} 
          </span>
        </span>
        <i nz-icon
           nzType="rise"
           nzTheme="outline"
           class="trade-status success"></i>
      </div>
      <span class="player-rating">
        <span class="rating">
          {{ player.Overall }}
        </span>
        <span class="title">
          {{ player.PlayerCardTypeDisplayName }}
        </span>
      </span>

    </div>
    <!-- <div class="list-item bg-gold-rare">
      <div class="item-left">
        <div class="player-photo">
          <img src="https://cdn.futbin.com/content/fifa20/img/players/158023.png?v=22" />
        </div>
        <img src="https://cdn.futbin.com/content/fifa20/img/clubs/241.png"
             class="player-club" />
        <img src="https://cdn.futbin.com/content/fifa20/img/nation/52.png"
             class="player-nation" />
        <span class="player-name">
          <span class="title">
            Lionel Messi
          </span>
          <span class="description">
            1,502,000 - 5 mins ago
          </span>
        </span>
        <i nz-icon
           nzType="fall"
           nzTheme="outline"
           class="trade-status danger"></i>
      </div>
      <span class="player-rating">
        <span class="rating">
          95
        </span>
        <span class="title">
          Gold Rare
        </span>
      </span>
    </div> -->
  </div>
</div>
