import { Component, HostListener, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router,ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { CreateUserAccountforWebRequest } from '../../models/CreateUserAccountforWebRequestDTO';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  createUserAccountforWebRequest : CreateUserAccountforWebRequest;
  errorMessage: string;
  pageTitle = 'Sign Up';
  PreferredPlatformId : number;
  PreferredPlatformTitle : string;
  IsApiCallProgress : boolean;
  isMobile: boolean = false;

  constructor(private authService: AuthService,
              private router: Router) {
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    this.detectScreenSize()
  }

  
  detectScreenSize() {
    if (window.innerWidth < 991) {
      this.isMobile = true
    } else {
      this.isMobile = false
    }
  }

  ngOnInit(): void {
    this.createUserAccountforWebRequest = new CreateUserAccountforWebRequest();
    this.PreferredPlatformTitle = 'Select Platform';
    this.IsApiCallProgress = false;
    this.detectScreenSize()
  }
  cancel(): void {
      this.router.navigate(['welcome']);
  }

  preferredPlatformSelect(PreferredPlatformId:number,signupForm: NgForm) {
    debugger
    switch(PreferredPlatformId){
      case 1: this.PreferredPlatformTitle = 'PS4'; break;
      case 2: this.PreferredPlatformTitle = 'XBox'; break;
    }
    signupForm.form.controls['preferredPlatformSelect'].updateValueAndValidity();
    this.PreferredPlatformId = PreferredPlatformId;
  }

  checkSignupFormValid(signupForm: NgForm) : boolean {
    let isFormValid : boolean = true;

    for (const field in signupForm.form.controls) { // 'field' is a string      
    switch(field){    
          case 'fullName':
            var fullnameRegex = /\s/g;
            if(!(fullnameRegex.test(signupForm.form.controls[field].value))){
              signupForm.form.controls[field].setErrors({'incorrect': true});
              isFormValid = false
            }
        break;
        case 'email':
            var emailRegex = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
            if(!(emailRegex.test(signupForm.form.controls[field].value))){
              signupForm.form.controls[field].setErrors({'incorrect': true});
              isFormValid = false
            }
        break;
        case 'userName':
            if(signupForm.form.controls[field].value === ''){
              signupForm.form.controls[field].setErrors({'incorrect': true});
              isFormValid = false
            }
        break;
        case 'password':
            if(signupForm.form.controls[field].value === ''){
              signupForm.form.controls[field].setErrors({'incorrect': true});
              isFormValid = false
            }
        break;
        case 'preferredPlatformSelect':
            if(this.PreferredPlatformId === undefined){
              signupForm.form.controls[field].setErrors({'incorrect': true});
              isFormValid = false
            }
        break;
      }
    }
    return isFormValid
  }

  async signup(signupForm: NgForm): Promise<void> {
    var $this = this;
      if (signupForm && signupForm.valid && this.checkSignupFormValid(signupForm)) {
        
        $this.IsApiCallProgress = true;
        $this.errorMessage = '';

        $this.createUserAccountforWebRequest.UserName = signupForm.form.value.userName;
        $this.createUserAccountforWebRequest.Password = signupForm.form.value.password;
        $this.createUserAccountforWebRequest.FullName = signupForm.form.value.fullName;
        $this.createUserAccountforWebRequest.Email = signupForm.form.value.email;
        $this.createUserAccountforWebRequest.PreferredPlatformId = $this.PreferredPlatformId;
        
        await $this.authService.signup($this.createUserAccountforWebRequest);
        
        if($this.authService.errorMessage != null && $this.authService.errorMessage != ''){
          $this.errorMessage = $this.authService.errorMessage;
          $this.IsApiCallProgress = false;
        }
        // if (this.authService.redirectUrl) {
        //     this.router.navigateByUrl(this.authService.redirectUrl);
        // } 
        else {
            $this.router.navigate(['/playerjournals']);
        }

      } else {
        //$this.errorMessage = 'Please enter all required fields in correct format.';
        $this.IsApiCallProgress = false;
      }
  }
}
