import { Injectable, OnInit} from '@angular/core';
// import { HttpClient } from '../shared/services/http-client.service';

import { IUser } from './user';
import { FutHttpClient} from '../shared/services/fut-http-client.service';
import { Observable } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { CreateUserAccountforWebRequestDTO } from '../models/CreateUserAccountforWebRequestDTO';
import { IApiResponse } from '../models/CommonDTO';

var dbConfig = require('../../lib/configLoader').databaseConfig;

@Injectable()
export class AuthService  implements OnInit{

    currentUser: IUser | null;
    redirectUrl: string;
    private url: string = dbConfig.apiurl;
    errorMessage: string;

    constructor(
        private http: FutHttpClient,
        private router: Router    
    ) {
        this.router.routeReuseStrategy.shouldReuseRoute = function(){
            return false;
         }
    
        this.router.events.subscribe((evt) => {
           if (evt instanceof NavigationEnd) {
              // trick the Router into believing it's last link wasn't previously loaded
              this.router.navigated = false;
              // if you need to scroll back to top, here is the right place
              window.scrollTo(0, 0);
           }
        });
    } 
    
    ngOnInit(): void {
        this.errorMessage = '';
    }

    isLoggedIn(): boolean {
        //return !!this.currentUser;
        var currentUserObject = localStorage.getItem('currentUser');
        if(currentUserObject != 'null' && currentUserObject != null){
            this.currentUser = JSON.parse(currentUserObject);
            return !!JSON.parse(currentUserObject).sessionId;
        }
        return false;
    }

    preferredPlatformId(): number {
        // console.log('this.currentUser.PreferredPlatformId');
        // console.log(this.currentUser.PreferredPlatformId);
        // return this.currentUser.PreferredPlatformId;
        var currentUserObject = localStorage.getItem('currentUser');
        if(currentUserObject != 'null' && currentUserObject != null){
            this.currentUser = JSON.parse(currentUserObject);
            return Number(JSON.parse(currentUserObject).PreferredPlatformId) ;
        }
        return null;
    }

    async preferredPlatformIdChange(platformId : number): Promise<void> {

        var currentUserObject = localStorage.getItem('currentUser');
        if(currentUserObject != 'null' && currentUserObject != null){
            this.currentUser = JSON.parse(currentUserObject);  
        }

        var params = {
            "SessionId": this.currentUser.sessionId,
            "PreferredPlatformId": platformId
         };
        let data = JSON.stringify(params);

       //step 1: make ajax call
        var  resultObject = await this.http.callBackEndAsync(this.url + 'api/user/ChangePreferredPlatform/','POST',null,data);

        if(resultObject != null){

            if(resultObject.Status.StatusType == 'Ok'){
                this.errorMessage = '';
                this.currentUser.PreferredPlatformId = platformId;  
                //step 2: change local storage
                localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
                //step 3: redirect to rout:  playerjournals 
                // this.router.navigate(['/playerjournals'],{ queryParams: { platformId: platformId }});
                this.router.navigate(['/playerjournals']);

             }
             else if(resultObject.Status.StatusType == 'OK_Warning' || resultObject.Status.StatusType == 'OK_Information'){
                 this.handleWarning(resultObject.Status.Errors[0]);
             }
             else{
                 this.handleError(resultObject.Status.Errors[0]);
             }
        }
    }
    
    public getSession(): IUser | null {
        var currentUserObject = localStorage.getItem('currentUser');

        if(currentUserObject != 'null' && currentUserObject != null){        
            this.currentUser = JSON.parse(currentUserObject);
            return JSON.parse(currentUserObject);

        }
        return null;
    }

    async login(userName: string, password: string): Promise<void> {

        this.errorMessage = '';
        var currentUserObject = this.getSession();
        if(currentUserObject != null)
        {
            localStorage.removeItem('currentUser');
        }

        //TODO: Change values for userName and password
        // var params = {
        //      "DeviceToken": "",
        //      "DeviceType": 3,
        //       "UserName": "cenkbut",
        //       "Password" : "318797cu"
        //   };
          var params = {
             "DeviceToken": "",
             "DeviceType": 3,
              "UserName": userName,
              "Password" : password
          };
        let data = JSON.stringify(params);

        //TODO: setup an interface ILogonuserResponse, for .subscribe((response : ILogonuserResponse) => {
        var resultObject = await this.http.callBackEndAsync(this.url + 'api/user/logonuser/','POST',null,data);

        if(resultObject != null){
            
            if(resultObject.Status.StatusType === 'Ok'){
                //make an ajax call to CheckUserSession(Guid sessionId, out User userFound)
                // AppVersion = 1.0 string, DeviceType : 3 = PC, 1= IOS, 2= Android   
                // SubscriptionType 0 - Free , 1 - Beta, , 2 - Gold , 3 - Platinum
                var paramsRequest = {
                    "SessionId": resultObject.SessionId,
                    "AppVersion": '1.0',
                    "DeviceType": 3
                };
                let dataRequest = JSON.stringify(paramsRequest);
                var  resultSessionObject = await this.http.callBackEndAsync(this.url + 'api/user/CheckUserSession/','POST',null,dataRequest);
                
                if(resultSessionObject != null){

                    if(resultSessionObject.Status.StatusType == 'Ok'){
                        this.currentUser = {
                            sessionId: resultObject.SessionId,
                            userId: resultSessionObject.UserId,
                            userName: userName,
                            isBetaUser : resultSessionObject.IsBetaUser,
                            SubscriptionType : resultSessionObject.SubscriptionType ,
                            IsSubscriptionActive : resultSessionObject.IsSubscriptionActive,
                            PreferredPlatformId : resultSessionObject.PreferredPlatformId,
                            PagingNumberSelected : 10
                        };
                        localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
                    }
                    else if(resultSessionObject.Status.StatusType == 'OK_Warning' || resultSessionObject.Status.StatusType == 'OK_Information'){
                        this.handleWarning(resultSessionObject.Status.Errors[0]);
                    }
                    else{
                        this.handleWarning(resultSessionObject.Status.Errors[0]);
                    }                                   
                }
            }
             else if(resultObject.Status.StatusType == 'OK_Warning' || resultObject.Status.StatusType == 'OK_Information'){
                 this.handleWarning(resultObject.Status.Errors[0]);
             }
             else{
                 this.handleWarning(resultObject.Status.Errors[0]);
             }       
        }
        else{
            this.errorMessage = "Login failed to your journal. Please try again.";
        }
    }

    async loginBySession(en_session: string): Promise<any> {

        try {            
            this.errorMessage = '';
            var de_session = atob(en_session);

            var paramsRequest = {
                "SessionId": de_session,
                "AppVersion": '1.0',
                "DeviceType": 3
            };
            let dataRequest = JSON.stringify(paramsRequest);
            var  resultSessionObject = await this.http.callBackEndAsync(this.url + 'api/user/CheckUserSession/','POST',null,dataRequest);
            
            if(resultSessionObject != null){

                if(resultSessionObject.Status.StatusType == 'Ok'){
                    this.currentUser = {
                        sessionId: de_session,
                        userId: resultSessionObject.UserId,
                        userName: resultSessionObject.UserName,
                        isBetaUser : resultSessionObject.IsBetaUser,
                        SubscriptionType : resultSessionObject.SubscriptionType ,
                        IsSubscriptionActive : resultSessionObject.IsSubscriptionActive,
                        PreferredPlatformId : resultSessionObject.PreferredPlatformId,
                        PagingNumberSelected : 10
                    };
                    localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
                }
                else if(resultSessionObject.Status.StatusType == 'OK_Warning' || resultSessionObject.Status.StatusType == 'OK_Information'){
                    this.handleError(resultSessionObject.Status.Errors[0]);                
                }
                else{
                    this.handleError(resultSessionObject.Status.Errors[0]);
                }                                    
            }
            else{
                this.errorMessage = "Login failed to your journal. Please try again.";
            }    
        }
        catch (e) {
            this.handleError(e); 
        }

    }
    async signup(createUserAccountforWebRequest : CreateUserAccountforWebRequestDTO): Promise<void> {

        this.errorMessage = '';
        var currentUserObject = this.getSession();
        if(currentUserObject != null)
        {
            localStorage.removeItem('currentUser');
        }

        let data = JSON.stringify(createUserAccountforWebRequest);

        //TODO: setup an interface ILogonuserResponse, for .subscribe((response : ILogonuserResponse) => {
        var  resultObject = await this.http.callBackEndAsync(this.url + 'api/user/CreateUserAccountforWeb/','POST',null,data);

        if(resultObject != null){
            
            if(resultObject.Status.StatusType == 'Ok'){
               await this.login(createUserAccountforWebRequest.UserName, createUserAccountforWebRequest.Password);
            }
            else if(resultObject.Status.StatusType == 'OK_Warning' || resultObject.Status.StatusType == 'OK_Information'){
                if(resultObject.Status.Errors != null)
                {
                    this.handleWarning(resultObject.Status.Errors[0]);
                }
                else
                {
                    this.handleWarning("An error has occurred, please try again later.");
                }
            }
            else{
                if(resultObject.Status.Errors != null)
                {
                    this.handleWarning(resultObject.Status.Errors[0]);
                }
                else
                {
                    this.handleWarning("An error has occurred, please try again later.");
                }
            }
        }
    }
    async forgotpassword(usernameorEmail: string): Promise<void> {

        this.errorMessage = '';
        var currentUserObject = this.getSession();        
        if(currentUserObject != null)
        {
            localStorage.removeItem('currentUser');
        }
        let data = JSON.stringify({ 'UsernameorEmail': usernameorEmail});

        //TODO: setup an interface ILogonuserResponse, for .subscribe((response : ILogonuserResponse) => {
        var  resultObject = await this.http.callBackEndAsync(this.url + 'api/user/ForgotMyPassword/','POST',null,data);

        if(resultObject != null){
            
            if(resultObject.Status.StatusType == 'Ok'){
               //message that email is sent 
               this.errorMessage = "Email sent successfully, please check your email for instructions.";
            }
            else if(resultObject.Status.StatusType == 'OK_Warning' || resultObject.Status.StatusType == 'OK_Information'){
                this.handleWarning(resultObject.Status.Errors[0]);
            }
            else{
                this.handleWarning(resultObject.Status.Errors[0]);
            }
        }
    }
    handleWarning(err: any):void {
        //this.logout();

        this.http.handleError(err);
        if(this.http.errorMessage != null || this.http.errorMessage != ''){
            this.errorMessage = this.http.errorMessage;
        }
    }

    handleError(err: any):void {
        this.logout();
        this.http.handleError(err);
        if(this.http.errorMessage != null || this.http.errorMessage != ''){
            this.errorMessage = this.http.errorMessage;
            alert(this.errorMessage);
        }
        else{
            alert("FUT Alert servers is under maintentance. Please try again later.");
        }
        this.router.navigate(['/login']);
    }
    //   handleError(err: HttpErrorResponse):void {
    //     // in a real world app, we may send the server to some remote logging infrastructure
    //         // instead of just logging it to the console
    //         let errorMessage: string;
    //         if (err.error instanceof Error) {
    //             // A client-side or network error occurred. Handle it accordingly.
    //             errorMessage = `An error occurred: ${err.error.message}`;
    //         } else if(err.message != null){
    //             // The backend returned an unsuccessful response code.
    //             // The response body may contain clues as to what went wrong,
    //             errorMessage = `Backend returned code ${err.status}, body was: ${err.message}`;
    //         }
    //         else{
    //           errorMessage = err.toString();;
    //         }
    //         console.error(errorMessage);
    //   }      
    logout(): void {

        // //1-Logoff from server
        // this.errorMessage = '';

        // var currentUserObject = this.getSession();        
        // if(currentUserObject != null)
        // {
        //     var paramsRequest = {
        //         "SessionId": currentUserObject.sessionId
        //     };
    
        //     let data = JSON.stringify(paramsRequest);
    
        //     //TODO: setup an interface ILogonuserResponse, for .subscribe((response : ILogonuserResponse) => {
        //     this.http.callBackEnd(this.url + 'api/user/DestroyUserSession/','POST',null,data)
        //     .subscribe(
        //         (response : any) => {
        //           if(response.Status.StatusType == 'Ok'){
        //             this.errorMessage = "logout successfully.";
        //           }
        //           else if(response.Status.StatusType == 'OK_Warning' || response.Status.StatusType == 'OK_Information'){
        //             this.handleWarning(response.Status.Errors[0]);
        //             }
        //             else{
        //                 this.handleWarning(response.Status.Errors[0]);
        //             }
        //         },
        //         err => { this.http.handleError(err);}
        //     );  
        // }           
        
        //2-Clear from local storage
        this.currentUser = null;
        localStorage.removeItem('currentUser');
    }
}
