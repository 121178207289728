<p-sidebar [(visible)]="visibleSidebar1" [baseZIndex]="10000">

  <h1 style="font-weight:normal">Left Sidebar</h1>

  <button pButton type="button" (click)="visibleSidebar1 = false" label="Save" class="ui-button-success">SAVE</button>

  <button pButton type="button" (click)="visibleSidebar1 = false" label="Cancel" class="ui-button-secondary">CANCEL</button>

</p-sidebar>
  
  
  
  <button pButton type="button" (click)="visibleSidebar1 = true" icon="pi pi-arrow-right">SIDEBAR</button>