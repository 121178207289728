import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { PlayerjournalService } from "../playerjournals/playerjournal.service";
import { CommaSeparatedNumberPipe } from '../shared/pipes/CommaSeparatedNumberPipe';

@Component({  
  selector: "app-trader-journals",  
  templateUrl: "./trader-journals.component.html",
  styleUrls: ["./trader-journals.component.css"],
  providers: [CommaSeparatedNumberPipe]
  
})
export class TraderJournalsComponent implements OnInit {
  playerJournalDTOs: any;
  pagingNumbers: any[] = [];
  pagingNumberSelected = 5;
  orderKey = 'desc';
  currentTotalProfit = 0;
  totalProfit = [];
  total: any;  
  loading = false;
  pageTitle = "TRADER'S INVESTMENTS";

  constructor(
    private activatedRoute: ActivatedRoute,
    private playerjournalService: PlayerjournalService, 
    private commaSeparatedNumber: CommaSeparatedNumberPipe
  ) { }

  ngOnInit() {
    this.loading = true;
    this.pagingNumbers = [1, 5, 10, 15, 20, 30, 50];
    const encryptedString = this.activatedRoute.snapshot.paramMap.get('encryptedString');
    if (!!encryptedString) {
      this.playerjournalService.getTraderJournals(encryptedString).subscribe(journals => {
        if (!!journals) {
          this.playerJournalDTOs = journals;
          
          
          this.loading = false;
          this.playerJournalDTOs.forEach(x => {
            this.pageTitle = x.username + "'S INVESTMENTS";
            this.totalProfit.push(parseFloat(x.totalInvestmentPL.replace(/,/g, '')));
            this.total = this.totalProfit.reduce((acc, cur) => {
              return acc + (cur ? cur : 0);
            });
          });
        }
        console.log(this.pageTitle);
        this.currentTotalProfit = this.total.toLocaleString();
      });
    }
  }

  commaSeparated(value: number) {
    return (value !=null) ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : value; // '12,000';Convert number to comma separated number string    
  }


  sort(FieldName) {
    // data.sort((a, b) => a.date - b.date)

    this.orderKey = this.orderKey === 'desc' ? 'asc' : 'desc';
    if (FieldName === 'date') {
      this.playerJournalDTOs.sort((a, b) => {
        const first = this.orderKey === 'desc' ? a : b;
        const second = this.orderKey === 'desc' ? b : a;
        return new Date(first.journalItem.createdDate).getTime() - new Date(second.journalItem.createdDate).getTime();
      });
    } else if (FieldName === 'boughtPrice') {
      this.playerJournalDTOs.sort((a, b) => {
        const first = this.orderKey === 'desc' ? a : b;
        const second = this.orderKey === 'desc' ? b : a;
        return first.journalItem.boughtPrice - second.journalItem.boughtPrice;
      });
    } else if (FieldName === 'fullName') {
      this.playerJournalDTOs.sort((a, b) => {
        const first = this.orderKey === 'desc' ? a : b;
        const second = this.orderKey === 'desc' ? b : a;
        return first.playerDetail.playerFullName.localeCompare(second.playerDetail.playerFullName);
      });
    } else if (FieldName === 'currentPrice') {
      this.playerJournalDTOs.sort((a, b) => {
        const first = this.orderKey === 'desc' ? a : b;
        const second = this.orderKey === 'desc' ? b : a;
        return first.playerDetail.currentPricePS4 - second.playerDetail.currentPricePS4;
      });
    } else if (FieldName = 'totalInvestmentPL') {
      this.playerJournalDTOs.sort((a, b) => {
        const first = this.orderKey === 'desc' ? a : b;
        const second = this.orderKey === 'desc' ? b : a;
        return parseFloat(first.totalInvestmentPL.replace(/,/g, '')) - parseFloat(second.totalInvestmentPL.replace(/,/g, ''));
      });
    }
  }

  ConvertString(value) {
    if (value) {
      // tslint:disable-next-line:radix
      return parseInt(value.replace(/\,/g, ''));
    } else {
      return 0;
    }
  }
}