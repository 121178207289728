<div class="container">
  <div class="card">
    <!-- <div class="card__header">
      <h4>Tax Calculator</h4>
    </div> -->
    <div class="">
      <div class="form-group" style="padding-left: 8px; padding-right: 8px;">
        <div class="" >
          <input
            class="form-control"
            id="amount"
            type="number"
            placeholder="Enter Amount"
            [(ngModel)]="amount"
            name="amount"
            style="color: white;"
          />
        </div>
      </div>
      <div class="table-responsive" style="padding-left: 8px;padding-right: 8px;">
        <table
          class="table table__cell-center table-wrap-bordered table-thead-color"
        >
          <thead>
            <tr>
              <td ><p class="tax_calculator_fonts" style="text-align: start;">Tax</p></td>
              <td>
                <p class="tax_calculator_fonts" *ngIf="amount" style="text-align: end;">{{ roundTaxAmount(amount)| number:'1.0-0' }}</p>
              </td>
            </tr>
            <tr>
              <td ><p class="tax_calculator_fonts" style="text-align: start;">Net</p></td>
              <td> <p class="tax_calculator_fonts" *ngIf="amount" style="text-align: end">{{roundNetAmount(amount)| number:'1.0-0' }}</p></td>
            </tr>
            <tr>
              <td ><p class="tax_calculator_fonts" style="text-align: start;">Break Even</p></td>
              <td> <p class="tax_calculator_fonts" *ngIf="amount" style="text-align: end;">{{ roundBreakEvenAmount(amount) | number:'1.0-0'}}</p></td>
            </tr>
          </thead>
        </table>
      </div>
      <!-- <div class="">
      <button
        class="btn btn-primary-inverse btn-lg btn-block"
        type="submit"
      ></button>
    </div> -->
      <div class="form-group" style="text-align: center">
        <button class="btn btn-sm btn-danger" (click)="amount = 0">Clear Calculator</button>
      </div>
    </div>
  </div>
</div>
