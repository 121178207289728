
export interface CreateUserAccountforWebRequestDTO {
    UserName : string,
    Password : string,
    FullName : string,
    Email : string,
    DeviceToken ?: string,
    DeviceType : number, //DeviceType : 3 = PC, 1= IOS, 2= Android 
    PreferredPlatformId : number
}
export class CreateUserAccountforWebRequest implements CreateUserAccountforWebRequestDTO {
 
    UserName: string;
    Password: string;
    FullName: string;
    Email: string;
    DeviceToken ?: string;
    DeviceType: number;
    PreferredPlatformId: number;
    constructor(){
        this.UserName = this.Password = this.FullName = this.Email = '';
        this.DeviceToken = '';
        this.DeviceType = 3;
        this.PreferredPlatformId = 1;
    }
}