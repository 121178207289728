import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { AngularFontAwesomeModule } from 'angular-font-awesome';
// import { ButtonModule, DialogModule, DropdownModule, InputTextModule } from 'primeng/primeng';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
//import {MenubarModule} from 'primeng/menubar';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CalculatorComponent } from './calculator/calculator.component';
import { PageNotFoundComponent } from './home/page-not-found.component';
import { ShellComponent } from './home/shell.component';
import { SidebarComponent } from './home/sidebar/sidebar.component';
// import { MenuComponent } from './home/menu.component';
import { WelcomeComponent } from './home/welcome.component';
/* Feature Modules */
import { UserModule } from './user/user.module';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import { MenuModule } from 'primeng/menu';
import { DropdownModule } from 'primeng/dropdown';
import { TraderJournalsComponent } from './trader-journals/trader-journals.component';
import { ModalModule } from 'ngx-bootstrap/modal';  
import { NgxPaginationModule } from 'ngx-pagination';
import { ToastrModule } from 'ngx-toastr';
// import { NgZorroAntdModule} from 'ng-zorro-antd';
import { en_US, NZ_I18N, NzI18nModule } from 'ng-zorro-antd/i18n';
import { NgxSpinnerModule } from "ngx-spinner";

//import { CommaSeparatedNumberPipe } from './shared/pipes/CommaSeparatedNumberPipe'

import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
registerLocaleData(en);


// import { MenuComponent } from './playerjournals/playerjournal-shell/menu.component';

@NgModule({
  declarations: [
    AppComponent,
    ShellComponent,
    // MenuComponent,
    WelcomeComponent,
    PageNotFoundComponent,
    SidebarComponent,
    CalculatorComponent,
    TraderJournalsComponent    
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    // AngularFontAwesomeModule,
    ModalModule.forRoot(),  
    ToastrModule.forRoot(),    
    NgxPaginationModule,
    // NgZorroAntdModule,
    NgxSpinnerModule,
    MessageModule,
    MessagesModule,
    NzI18nModule,
    MenuModule,
    DropdownModule,
    // PlayerjournalModule,
    //HttpClientInMemoryWebApiModule.forRoot(ProductData),
    UserModule,
    AppRoutingModule,
    SidebarModule,    
    TableModule,
    BrowserAnimationsModule,
    DropdownModule,
    ProgressSpinnerModule    
  ],
  providers: [{ provide: NZ_I18N, useValue: en_US },],
  bootstrap: [AppComponent],
//   entryComponents: [
//     PlayerjournalShellListComponent

//  ]

})
export class AppModule { }
