<div class="container-fluid">
    <div class="row">
        <div class="row mt-5">
            <div class="col-12 col-md-12">
                <div class="row justify-content-center mt-5">
                    <div class="col-12 col-md-12 mb-3 d-flex justify-content-center text-center">
                        <div class="col-md-5 col-sm-6">
                            <!-- desktop logo -->
                            <div class="col-md-3 col-sm-12 mt-2 ml-3" *ngIf="!isMobile" [routerLink]="['/welcome']">
                                <img src="https://cdn.futalert.co.uk/Images/FCAlertLogo-WhiteBeta.png" alt="Fut alert logo" style="width: 12rem;">
                            </div>

                            <!-- mobile logo -->
                            <div class="col-md-3 col-sm-12 mt-1 ml-3 text-center" *ngIf="isMobile"
                                [routerLink]="['/welcome']">
                                <img src="https://cdn.futalert.co.uk/Images/FCAlertLogo-WhiteBeta.png" alt="Fut alert logo" style="width: 12rem;">
                            </div>
                        </div>
                    </div>
                    <img class="img-responsive ml-3 mb-3"
                        src="https://corporate.futalert.co.uk/content/images/iphonex-new-small.png"
                        style="width: 100%; max-width: 12rem; height: auto;" alt="screens">
                    <img class="img-responsive" src="https://cdn.futalert.co.uk/Images/flipping-list-iphone.png"
                        style="width: 100%; max-width: 14rem; height: auto;" alt="screens">
                </div>
                <div class="col-12 text-center mt-3">
                    <h4 class="text-white">ON MOBILE</h4>
                    <div class="d-flex justify-content-center">
                        <a type="button" target='_blank' href='https://itunes.apple.com/us/app/futalert/id1358652393'
                            class="btn btn-default btn-fut"
                            style="width:160px; background: transparent; border-color: transparent;"><img
                                src="/assets/icons/download_apple.png" style="width: 100%; max-width: 150px;"></a>
                        <a type="button" target='_blank'
                            href='https://play.google.com/store/apps/details?id=uk.co.elcsoft.futalert'
                            class="btn btn-success btn-fut"
                            style="width:160px;background: transparent; border-color: transparent;"><img
                                src="/assets/icons/download_google.png" style="width: 100%; max-width: 150px;"></a>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-md-7">
            <div class="col-md-7 mt-5 mb-5 text-center"
                style="color: white; font-family:DIN Pro Bold; font-size: 30px; font-weight: 550; margin-bottom: 5rem !important;">
                The #1 Tools For
                <br>
                EA FC Ultimate Team Traders
            </div>
            <div class="row justify-content-center mt-5">
                <div class="col-11 col-md-7">

                    <h2 class="text-center text-white">My Portfolio</h2>
                    <form novalidate (ngSubmit)="login(loginForm)" #loginForm="ngForm" autocomplete="off">
                        <div class="form-group" [ngClass]="{'has-error': (userNameVar.touched || 
                        userNameVar.dirty) && 
                        !userNameVar.valid }">
                            <label for="userNameId" class="text-white">Username</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="fas fa-user"></i></span>
                                </div>
                                <input class="form-control" id="userNameId" type="text" required ngModel name="userName"
                                    #userNameVar="ngModel">
                            </div>
                            <div style="display: block; color: #dc3545; font-size: 14px; margin-top: 5px;" *ngIf="(userNameVar.touched ||
                                userNameVar.dirty) &&
                                userNameVar.errors">
                                <span *ngIf="userNameVar.errors.required">User name is required.</span>
                            </div>
                        </div>
                        <div class="form-group" [ngClass]="{'has-error': (passwordVar.touched || 
                        passwordVar.dirty) && 
                        !passwordVar.valid }">
                            <label for="password" class="text-white">Password</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="fas fa-lock"></i></span>
                                </div>
                                <input class="form-control" id="passwordId" type="password" required ngModel
                                    name="password" #passwordVar="ngModel">
                            </div>
                            <div style="display: block; color: #dc3545; font-size: 14px; margin-top: 5px;"
                                *ngIf="(passwordVar.touched || passwordVar.dirty) && passwordVar.errors">
                                <span *ngIf="passwordVar.errors.required">Password is required.</span>
                            </div>
                        </div>
                        <div class="form-check d-flex" style="justify-content: space-between;">
                            <input type="checkbox" class="form-check-input" id="rememberMe">
                            <label class="form-check-label" for="rememberMe">Remember me</label>
                            <button type="submit" class="btn btn-primary" type="submit">Log in</button>
                        </div>
                        <div class="d-flex" style="justify-content: center;">

                        </div>
                    </form>
                    <div class="text-center mt-3">
                        <p>Not yet registered? <a routerLink='/signup'>Sign Up</a></p>
                        <p><a routerLink="/forgotpassword">Forgot my password?</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<!-- desktop alert -->
<div class="d-sm-none d-md-block" style="position: fixed; bottom: 0; width: 45%; padding: 15px;" *ngIf="!isMobile">
    <div class="alert alert-danger" role="alert" *ngIf="isError">
        {{errorMessage}}
        <button type="button" class="close" (click)="isError = false">&times;</button>
    </div>
</div>

<!-- mobile alert -->
<div class="d-sm-none d-md-block" style="position: fixed; bottom: 0; width: 100%;" *ngIf="isMobile">
    <div class="alert alert-danger" role="alert" *ngIf="isError">
        {{errorMessage}}
        <button type="button" class="close" (click)="isError = false">&times;</button>
    </div>
</div>

<footer class="footer-n mobile-footer">
    <div class="col-12 col-sm-12">
        <div class="text--center">
            <!-- FUT Alert Adsense -->
            <ins class="adsbygoogle" style="display:inline-block;width:728px;height:90px"
                data-ad-client="ca-pub-8212701613856478" data-ad-slot="3040602989"></ins>
            <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
            </script>
            <br><br>
            <span>© 2020 FUT Alert, developed by&nbsp;</span> <a target="_blank"
                href="http://www.elcsoft.co.uk">elcsoft.co.uk</a> | <a target="_blank"
                href="http://www.futalert.co.uk/privacypolicy">Privacy Policy</a> | <a target="_blank"
                href="http://www.futalert.co.uk/FAQ">Help &amp; FAQ</a> | <a target="_blank"
                href="https://twitter.com/fut_alert">Support</a> | Contact Us: <a
                href="mailto:support@FUTAlert.co.uk">support@futalert.co.uk</a>
            <br><br>
            <p>All player, team and league related images / logos are property of&nbsp;EA Sports</p>
        </div>
    </div>
</footer>