<div class="container-fluid" style="min-height: 100vh; display: flex; flex-direction: column; width: fit-content; overflow: hidden; padding: 0 !important;">
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12" style="min-height: 900px;">
            <div style="height: 915px; padding-top: 0; padding-bottom: 10px;">
                <div class="row text-center mb-4">
                    <div class="col-12 col-md-12">
                        <img src="/assets/icons/FUT_logo.png" style="width: 7em; margin-top:30px" />
                    </div>
                </div>

                <div class="container">
                    <h1
                        style="color: #ffffff;text-align: center; font-family: 'DIN Pro Bold', 'Open Sans', sans-serif;font-size: 30px;font-weight: 550;margin-top: -10px;">
                        MY PORTFOLIO</h1>
                    <div class="row mb-5">
                        <div class="col-12 col-sm-12 col-md-12 mobile_screen wow fadeInUp animated"
                            data-wow-duration="1s"
                            style="visibility: visible;animation-duration: 1s;animation-name: fadeInUp;">
                            <div class="col-12 col-sm-12 col-md-12 wow fadeInUp animated mb-5" data-wow-duration="1s"
                                style="visibility: visible; animation-duration: 1s; animation-name: fadeInUp;">
                                <img class="img-fluid"
                                    src="https://cdn.futalert.co.uk/Images/new-portfolio-ss-desktop.jpg" alt="screens">
                                <div class="slide--bio mt-3"
                                    style="color: #ffffff;text-align: center; font-family: 'DIN Pro Medium', 'Open Sans', sans-serif;font-size: 25px;font-weight: 400;line-height: 32px;margin-bottom: 29px;">
                                    - Track the value of your investments and club players in real time! <br> - Our live pricing engine calculates your profit & loss as their values change. <br>-
                                    Set alerts to notify you when your investments hit a certain price!
                                </div>
                                <div class="slide--bio mb-5"
                                    style="color: #ffffff;text-align: center; font-family: 'DIN Pro Bold', 'Open Sans', sans-serif;font-size: 25px;font-weight: 550;line-height: 32px;margin-bottom: 29px;">
                                    Start tracking by joining "My Portfolio", NOW!
                                </div>
                                <div class="slide--bio" style="text-align: center; margin-bottom: 4rem;">
                                    <div class="btn-group btn_login_signup" role="group" aria-label="Basic example">
                                        <a type="button" href='/login'
                                            class="btn btn-default btn-fut btn-fut-login mb-2 mr-3"><img
                                                src="/assets/icons/login.png" class="img-fluid" alt="Login"></a>
                                        <a type="button" href='/signup'
                                            class="btn btn-success btn-fut btn-fut-signup ml-3"><img
                                                src="/assets/icons/signup.png" class="img-fluid" alt="Signup"></a>
                                    </div>
                                </div>
                            </div>

                            <div class="d-flex justify-content-center mb-2">
                                <div class="row mobile-phone-ss">
                                    <div class="col-12 d-flex justify-content-center">
                                        <div>
                                            <img class="img-fluid"
                                                src="https://cdn.futalert.co.uk/Images/market-analysis-iphone.png"
                                                alt="Market Analysis iPhone">
                                        </div>
                                        <div>
                                            <img class="img-fluid"
                                                src="https://corporate.futalert.co.uk/content/images/iphonex-new-small.png"
                                                alt="iPhone X">
                                        </div>
                                        <div>
                                            <img class="img-fluid"
                                                src="https://cdn.futalert.co.uk/Images/flipping-list-iphone.png"
                                                alt="Flipping List iPhone">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style="display: flex; justify-content: center; text-align: center;">
                                <div class="slide--bio"
                                    style="color: #ffffff;text-align: center; font-family: 'DIN Pro Medium', 'Open Sans', sans-serif;font-size: 25px;font-weight: 400;line-height: 32px;margin-bottom: 29px;">
                                    - Get notifications / alerts to trade your cards in and out effectively. <br> 
                                    - Set market, NLC and flipping alerts to keep you up to date! <br>
                                    - Regular updates - Keep track of all of the latest FUT items.
                                </div>
                            </div>
                            <div class="slide--bio"
                                style="color: #ffffff;text-align: center; font-family: 'DIN Pro Bold', 'Open Sans', sans-serif;font-size: 25px;font-weight: 550;line-height: 32px;margin-bottom: 29px;">
                                <span>&nbsp; Download our mobile apps from your app stores NOW!</span>
                            </div>
                            <div class="slide--bio mb-5" style="text-align: center; ">
                                <div class="btn-group" role="group" aria-label="Basic example">
                                    <a type="button" target='_blank'
                                        href='https://itunes.apple.com/us/app/futalert/id1358652393'
                                        class="btn btn-default btn-fut"
                                        style="width:160px; background: transparent; border-color: transparent; padding-right: 1rem;"><img
                                            src="/assets/icons/download_apple.png" class="img-fluid"
                                            alt="Download on the App Store"></a>
                                    <a type="button" target='_blank'
                                        href='https://play.google.com/store/apps/details?id=uk.co.elcsoft.futalert'
                                        class="btn btn-success btn-fut"
                                        style="width:160px;background: transparent; border-color: transparent; padding-left: 1rem;"><img
                                            src="/assets/icons/download_google.png" class="img-fluid"
                                            alt="Get it on Google Play"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer class="footer-n mobile-footer">
            <div class="col-12 col-sm-12">
                <div class="text--center">
                    <!-- FUT Alert Adsense -->
                    <ins class="adsbygoogle" style="display:inline-block;width:728px;height:90px"
                        data-ad-client="ca-pub-8212701613856478" data-ad-slot="3040602989"></ins>
                    <script>
                        (adsbygoogle = window.adsbygoogle || []).push({});
                    </script>
                    <br><br>
                    <span>© 2020 FUT Alert, developed by&nbsp;</span> <a target="_blank"
                        href="http://www.elcsoft.co.uk">elcsoft.co.uk</a> | <a target="_blank"
                        href="http://www.futalert.co.uk/privacypolicy">Privacy Policy</a> | <a target="_blank"
                        href="http://www.futalert.co.uk/FAQ">Help &amp; FAQ</a> | <a target="_blank"
                        href="https://twitter.com/fut_alert">Support</a> | Contact Us: <a
                        href="mailto:support@FUTAlert.co.uk">support@futalert.co.uk</a>
                    <br><br>
                    <p>All player, team and league related images / logos are property of&nbsp;EA Sports</p>
                </div>
            </div>
    </footer>
</div>