import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { StarComponent } from './star.component';
import { CriteriaComponent } from './criteria/criteria.component';
import { TopBannerComponent } from '../home/TopBannerComponent'
import { PlayerSearchComponent } from './player-search/player-search.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { TaxCalculatorComponent } from './tax-calculator/tax-calculator.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NzButtonModule,
  ],
  declarations: [
    StarComponent,
    CriteriaComponent,
    TopBannerComponent,
    PlayerSearchComponent,
    TaxCalculatorComponent
  ],
  exports: [
    StarComponent,
    PlayerSearchComponent,
    TaxCalculatorComponent,
    CriteriaComponent,
    TopBannerComponent,
    CommonModule,
    FormsModule
  ]
})
export class SharedModule { }
