import {Component,OnInit,AfterViewInit} from '@angular/core'

@Component({
      moduleId: module.id,
      selector: 'google-adsense',
      template: '<ins class="adsbygoogle" style="display:block" data-ad-client="ca-pub-8212701613856478" data-ad-slot="3040602989" data-ad-format="auto"></ins><br>'
    })

export class TopBannerComponent implements AfterViewInit {

  constructor() {    
  } 

  ngAfterViewInit() {    
    setTimeout(()=>{    
      try{
        (window['adsbygoogle'] = window['adsbygoogle'] || []).push({});
      }catch(e){
        console.error("error");
      }        
    },2000);
  }     
}  