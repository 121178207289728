import { Injectable, OnInit } from '@angular/core';
//import {Http, Headers, Response, Request,RequestOptionsArgs, BaseRequestOptions, RequestMethod} from '@angular/http';

import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';

import {Observable} from "rxjs";
import { IUser } from '../../user/user';
import { resolve } from 'dns';
import { reject } from 'q';

// import 'rxjs/add/observable/of';
// import 'rxjs/add/operator/catch';
// import 'rxjs/add/operator/debounceTime';
// import 'rxjs/add/operator/distinctUntilChanged';
// import 'rxjs/add/operator/do';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/switchMap';

@Injectable({
  providedIn: 'root'
})
export class FutHttpClient implements OnInit{

  errorMessage: string;
  // public headers = new HttpHeaders({
  //     'Content-Type': 'application/json'
  // });

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.errorMessage = '';
  }

  // public createAuthorizationHeader() {
  //   //TODO: get session id from localstorage
  //   console.log("creater before");
  //   console.log(this.headers);
  //   this.headers.append('sessionid', '1234 '); 
  //   console.log("creater after");
  //   console.log(this.headers);
  // }
  public getSession(): IUser | null {
    var currentUserObject = localStorage.getItem('currentUser');

    if(currentUserObject != 'null' && currentUserObject != null){
      //return JSON.parse(currentUserObject).sessionId;
      return JSON.parse(currentUserObject);
    }
    return null;
  }
  // public isAuthenticated(): boolean {
  //   // get the token
  //   const token = this.getSession();
  //   // return a boolean reflecting 
  //   // whether or not the token is expired
  //   return tokenNotExpired(null, token);
  // }
  public callBackEnd(relativeEndPoint: string,reqMethod: string,
    reqParams?: HttpParams, objBody?: any): Observable<any> {

    var $this = this;
    $this.errorMessage ='';
    const reqUrl = relativeEndPoint;

    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    var sessionid = (this.getSession() != null) ? this.getSession().sessionId : null;
    if(sessionid != null)
    {
      headers = headers.append('sessionid', sessionid);
    }
    
    return this.http.request<any>(reqMethod, reqUrl,
        {
            body: objBody,
            headers:  headers,
            params: reqParams,
            responseType: 'json',
            withCredentials: false
        }
    );
  }

  public callBackEndFile(relativeEndPoint: string,reqMethod: string,
    reqParams?: HttpParams, objBody?: any): Observable<any> {

    var $this = this;
    $this.errorMessage ='';
    const reqUrl = relativeEndPoint;

    let headers = new HttpHeaders();

    var sessionid = (this.getSession() != null) ? this.getSession().sessionId : null;
    if(sessionid != null)
    {
      headers = headers.append('sessionid', sessionid);
    }
    
    return this.http.request<any>(reqMethod, reqUrl,
        {
            body: objBody,
            headers:  headers,
            params: reqParams,
            responseType: 'json',
            withCredentials: false
        }
    );
  }

  //async callBackEndAsync(reqUrl: string,reqParams?: HttpParams, objBody?: any): Promise<any> {
  async callBackEndAsync(relativeEndPoint: string,reqMethod: string,
      reqParams?: HttpParams, objBody?: any):  Promise<any> {

    var $this = this;
    $this.errorMessage ='';
    const reqUrl = relativeEndPoint;
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    var sessionid = (this.getSession() != null) ? this.getSession().sessionId : null;
    if(sessionid != null)
    {
      headers = headers.append('sessionid', sessionid);
    }

    return await this.http.request<any>(reqMethod, reqUrl,
      {
          body: objBody,
          headers:  headers,
          params: reqParams,
          responseType: 'json',
          withCredentials: false
      }
    )
    .toPromise()
    .then(
      result => { return result;},
      msg =>  { reject(msg)}
    );
  }
  handleError(err: HttpErrorResponse):void {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    var $this = this;
    
    if (err.error instanceof Error) {
        // A client-side or network error occurred. Handle it accordingly.
        $this.errorMessage = 'An error occurred: ${err.error.message}';
    } else if(err.message != null){
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        console.log(`Backend returned code ${err.status}, body was: ${err.message}`);
        $this.errorMessage = 'FUT Alert servers is under maintentance. Please try again later.';
    }
    else{
      $this.errorMessage = err.toString();;
    }
    //console.error($this.errorMessage);
    //return new throwError(errorMessage);
    
  }
}
