import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../user/auth.service';

@Component({
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.css']
})



export class WelcomeComponent implements OnInit{
    public pageTitle: string = 'Welcome';

    constructor(private authService: AuthService,
        private router: Router){}

    ngOnInit(): void {
        if(this.authService.isLoggedIn()){
            this.router.navigate(['/playerjournals']);
        }
    }
}
