import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { LoginComponent } from './login.component';
import { LoginBySessionComponent } from './loginbysession.component';
import { FutHttpClient} from '../shared/services/fut-http-client.service';
import { AuthService } from './auth.service';
import { AuthGuard } from './auth-guard.service';

import { SharedModule } from '../shared/shared.module';
import { SignupComponent } from './signup/signup.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import {ProgressSpinnerModule} from 'primeng/progressspinner';

@NgModule({
  imports: [
    SharedModule,
    ProgressSpinnerModule,
    RouterModule.forChild([
      { path: 'login', component: LoginComponent },
      { path: 'loginbysession', component: LoginBySessionComponent },
      { path: 'signup', component: SignupComponent },
      { path: 'forgotpassword', component: ForgotpasswordComponent }
    ])
  ],
  declarations: [
    LoginComponent,
    LoginBySessionComponent,
    SignupComponent,
    ForgotpasswordComponent
  ],
  providers: [
    AuthService,
    AuthGuard,
    FutHttpClient
  ]
})
export class UserModule { }
