import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pm-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  
  visibleSidebar1;
  
  constructor() { }

  ngOnInit() {
  }

}
