import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from './auth.service';

@Component({
    templateUrl: './loginbysession.component.html',
    styleUrls: ['./loginbysession.component.css']
})
export class LoginBySessionComponent implements OnInit{
    errorMessage: string;
    pageTitle = 'login by session';
    IsApiCallProgress: boolean;

    constructor(private authService: AuthService,
                private router: Router) {
    }
    ngOnInit(): void {

        this.authService.logout();
        this.IsApiCallProgress = false;

        let search: string = window.location.search.substring(window.location.search.indexOf("=")+1);
        
        if(search != ''){
            this.loginBySession(search);
        }
        else{
            this.router.navigate(['/login']);
        }
    }

    async loginBySession(en_session: string): Promise<void> {
        if (en_session != '') {

            this.IsApiCallProgress = true;
            this.errorMessage = '';

            await this.authService.loginBySession(en_session);

            if(this.authService.errorMessage != null && this.authService.errorMessage != ''){
                this.errorMessage = this.authService.errorMessage;
                this.IsApiCallProgress = false;
            }
            else {
                //TODO: after login redirect to personalize page
                this.router.navigate(['/playerjournals']);
            }
        } else {
            this.errorMessage = 'Please enter a user name and password.';
            this.IsApiCallProgress = false;
        }
    }
}
