<div class="forgotpassword_container" style="margin-top: 1em;">
    <div class="col-xs-6 col-sm-9 col-md-6  col-sm-offset-3">
        <form class="form" novalidate (ngSubmit)="forgotpassword(forgotpasswordForm)" #forgotpasswordForm="ngForm" id="forgotpassword" autocomplete="off">
            <fieldset>
                <legend>Forgot my password</legend>
                
                <div class="form-group" [ngClass]="{'has-error': (usernameorEmailVar.touched || 
                                                    usernameorEmailVar.dirty) && 
                                                    !usernameorEmailVar.valid }">
                  <label class="control-label" for="emailId">Username / Email</label>
                  <div class="">
                    <input class="form-control" 
                          id="emailId" 
                          type="text" 
                          placeholder="Please enter your username or email (required)" 
                          required
                          ngModel
                      name="usernameorEmail" #usernameorEmailVar="ngModel" />
                    <span class="help-block" *ngIf="(usernameorEmailVar.touched ||
                                                    usernameorEmailVar.dirty) &&
                                                    usernameorEmailVar.errors">
                      <span *ngIf="usernameorEmailVar.errors.required">
                          Username or Email is required.
                      </span>
                    </span>
                  </div>
                </div>                
                <div class="form-group">
                    <div class="col-xs-12" style="padding-left: 0px;padding-right: 0px;">
                        <div class="btn-group" role="group" aria-label="Basic example" style="width: 100%;">
                            <button class="btn btn-primary"
                                    type="submit"
                                    style="width:100%;margin-right:10px"
                                    [disabled]="!forgotpasswordForm.valid">
                                Remind me
                            </button>                                    
                        </div>
                        <p>*If you've forgotten both your username and email, please contact us</p>  
                    </div>
                </div> 
                <div style="text-align: center;margin: 50px 0px 50px 0px;" *ngIf="IsApiCallProgress" >
                        <br>
                        <p-progressSpinner [style]="{width: '50px', height: '50px'}" style="margin-top: 10px; margin-bottom: 10px" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>        
                </div>  
                <div class="form-group" *ngIf="errorMessage">
                    <div class="col-xs-12" style="padding-left: 0px;padding-right: 0px;">
                        <div class="col-xs-12" style="text-align: center;">
                            <div class="has-error">{{errorMessage}}</div>      
                        </div>                
                        <div style="clear: both;"></div>
                    </div>
                </div>       
                <div class="col-xs-4 col-sm-4  col-xs-offset-4 ">
                    <br><br>
                    <div class="row">
                        <div class="col-xs-12">
                            <img src= "/assets/icons/FUT_logo.png"  style="width: 7em;"/>
                        </div>                
                        <div style="clear: both;"></div>
                    </div>        
                </div>
            </fieldset>
        </form>

    </div>
</div>