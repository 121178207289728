import { Component, HostListener, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from './auth.service';

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit{
    errorMessage: string;
    isError: boolean = false
    pageTitle = 'Log In';
    IsApiCallProgress: boolean;
    isMobile: boolean = false

    constructor(private authService: AuthService,
                private router: Router) {
    }

    @HostListener('window:resize', ['$event'])
    onWindowResize(event) {
      this.detectScreenSize()
    }

    ngOnInit(): void {
        this.authService.logout();
        this.IsApiCallProgress = false;
        this.detectScreenSize()
    }
    cancel(): void {
        this.router.navigate(['welcome']);
    }

    detectScreenSize() {
        if (window.innerWidth < 991) {
          this.isMobile = true
        } else {
          this.isMobile = false
        }
      }

    async login(loginForm: NgForm): Promise<void> {
        if (loginForm && loginForm.valid) {
            const userName = loginForm.form.value.userName;
            const password = loginForm.form.value.password;
            this.IsApiCallProgress = true;
            this.errorMessage = '';
            await this.authService.login(userName, password);

            if(this.authService.errorMessage != null && this.authService.errorMessage != ''){
                this.isError = true
                this.errorMessage = this.authService.errorMessage;
                this.IsApiCallProgress = false;
                setTimeout(() => {
                    this.isError = false
                }, 5000);
            }
            // else if (this.authService.redirectUrl) {
            //     this.router.navigateByUrl(this.authService.redirectUrl);
            // } 
            else {
                //TODO: after login redirect to personalize page
                this.router.navigate(['/playerjournals']);
                //this.router.navigate(['/products']);
            }
        } else {
            this.errorMessage = 'Please enter a username and password.';
            this.IsApiCallProgress = false;
        }
    }    
}
