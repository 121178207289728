import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { map, min } from 'rxjs/operators';
import { FutHttpClient } from '../shared/services/fut-http-client.service';
import { AuthService } from '../user/auth.service';
import { ProfileDetailsDTO } from '../models/ProfileDetailsDTO';
import { Session } from 'protractor';


var dbConfig = require('../../lib/configLoader').databaseConfig;

@Injectable({
  providedIn: 'root'
})
export class PlayerjournalService implements OnInit {
  errorMessage: string;
  private url: any;

  ngOnInit(): void {
    //throw new Error("Method not implemented.");
    this.errorMessage = '';
  }
  constructor(
    private http: FutHttpClient,
    private authService: AuthService,
    private router: Router,
    private _http: HttpClient
  ) {
    this.url = dbConfig.apiurl;
  }

  getJournalTimersData(): Observable<any> {
    if (this.authService.isLoggedIn() && this.authService.currentUser) {
      return this.http.callBackEnd(this.url + '/Message/GetJournalTimers/', 'GET', null, null);
    }
    //return Observable.throw('User is not logged in, please login to proceed');
    return throwError('User is not logged in, please login to proceed');

  }
  async getInitialData(): Promise<any> {
    if (this.authService.isLoggedIn() && this.authService.currentUser) {
      return await this.http.callBackEndAsync(this.url + '/Message/gets/', 'GET', null, null);
    }
    //return Observable.throw('User is not logged in, please login to proceed');
    return throwError('User is not logged in, please login to proceed');

  }
  async getInitialDataPast(): Promise<any> {
    if (this.authService.isLoggedIn() && this.authService.currentUser) {
      return await this.http.callBackEndAsync(this.url + '/Message/GetPastTrades/', 'GET', null, null);
    }
    return throwError('User is not logged in, please login to proceed');

  }
  getPortfolioSummary(): Observable<any> {
    if (this.authService.isLoggedIn() && this.authService.currentUser) {
      return this.http.callBackEnd(this.url + '/Message/GetPortfolioSummary/', 'POST', null, null);
    }
    //return Observable.throw('User is not logged in, please login to proceed');
    return throwError('User is not logged in, please login to proceed');

  }
  getPortfolioSummaryPastTrades(): Observable<any> {
    if (this.authService.isLoggedIn() && this.authService.currentUser) {
      return this.http.callBackEnd(this.url + '/Message/GetPortfolioSummaryPastTrades/', 'POST', null, null);
    }
    //return Observable.throw('User is not logged in, please login to proceed');
    return throwError('User is not logged in, please login to proceed');

  }

  // async getFilterLookups(): Promise<any> {
  //   if(this.authService.isLoggedIn() && this.authService.currentUser)
  //   {
  //      return await this.http.callBackEndAsync(this.url + 'api/Player/getfilterlookups/','GET',null,null);
  //   }
  //   //return Observable.throw('User is not logged in, please login to proceed');
  //   return throwError('User is not logged in, please login to proceed');    
  // }
  getFilterLookups(): Observable<any> {
    if (this.authService.isLoggedIn() && this.authService.currentUser) {
      return this.http.callBackEnd(this.url + '/Message/getfilterlookups/', 'GET', null, null);
    }
    //return Observable.throw('User is not logged in, please login to proceed');
    return throwError('User is not logged in, please login to proceed');

  }
  async searchForPlayerWithKey(data: string): Promise<any> {
    if (this.authService.isLoggedIn() && this.authService.currentUser) {
      return await this.http.callBackEndAsync(this.url + 'api/Player/SearchForPlayerForWeb/', 'POST', null, data);
    }
    //return Observable.throw('User is not logged in, please login to proceed');
    return throwError('User is not logged in, please login to proceed');

  }

  async searchForPlayer(): Promise<any> {
    if (this.authService.isLoggedIn() && this.authService.currentUser) {
      return await this.http.callBackEndAsync(this.url + 'api/Player/GetAllPlayers/', 'POST', null, null);
    }
    //return Observable.throw('User is not logged in, please login to proceed');
    return throwError('User is not logged in, please login to proceed');

  }
  savePlayerJournal(data: string): Observable<any> {
    if (this.authService.isLoggedIn() && this.authService.currentUser) {
      return this.http.callBackEnd(this.url + 'Message/SaveJournal/', 'POST', null, data);
      // .pipe(
      //   map( result => {
      //     console.log(result)
      //   }),
      //   tap(modifiedResult => console.log(modifiedResult))        
      // );
    }
    return throwError('Jounal is not saved.');
  }
  sellJournalItem(data: string): Observable<any> {
    if (this.authService.isLoggedIn() && this.authService.currentUser) {
      return this.http.callBackEnd(this.url + 'Message/SellJournalItem/', 'POST', null, data);
    }
    return throwError('Jounal sell is not saved.');
  }
  deletePlayerJournal(data: string): Observable<any> {
    if (this.authService.isLoggedIn && this.authService.currentUser) {
      return this.http.callBackEnd(this.url + 'Message/DeleteJournal/', 'POST', null, data);
    }
    return throwError('Journal is not deleted.');
  }
  deleteAllPlayerJournal(data: any): Observable<any> {
    if (this.authService.isLoggedIn && this.authService.currentUser) {
      return this.http.callBackEnd(this.url + 'Message/DeleteAllJournal/', 'POST', null, data);
    }
    return throwError('Journal is not deleted.');
  }
  importTrades(data: any): Observable<any> {
    if (this.authService.isLoggedIn && this.authService.currentUser) {
      return this.http.callBackEndFile(this.url + 'api/Player/ImportTrades/', 'POST', null, data);
    }
    return throwError('Journal is not deleted.');
  }
  // handleError(err: any):void {
  //   this.http.handleError(err);

  //   alert(this.errorMessage)
  //   this.authService.logout();
  //   this.router.navigate(['/login']);
  // }

  handleError(err: any): void {
    this.authService.handleError(err);
  }
  handleWarning(err: any): void {
    this.authService.handleWarning(err);
    if (this.authService.errorMessage != null && this.authService.errorMessage != '') {
      this.errorMessage = this.authService.errorMessage;
    }
  }

  encryptUrlPart(username: string) {
    if (this.authService.isLoggedIn && this.authService.currentUser) {
      return this._http.get(`${this.url}/Message/EncryptUrlPart?username=${username}`, {
        responseType: 'text'
      }).pipe(
        map(res => res)
      );
    }
    return throwError('Error on encrypt url');
  }

  getTraderJournals(encryptedString: string) {
   
      console.log(encryptedString)
      return this.http.callBackEnd(this.url + `Message/getjournalsoftrader?encryptedstring=${encryptedString}`, 'GET', null, null);

   //   return this.http.callBackEnd()
      // return this._http.get(`${this.url}/Message/getjournalsoftrader?encryptedstring=${encryptedString}`).pipe(
      //   map(res => res)
      // );
  
  }

  getPlayerClubBadge(): any {
    return this._http.post(this.url + '/api/Player/GetFilterLookups', '')
  }

  getPlayerCardTypes(generation, isTradable?): any {
    return this._http.post(this.url + 'api/Player/GetPlayerCardTypes', {
      Generation: generation,
      TradableEnforced: isTradable,
    })
  }
  getProfileDetails(sessionId): Observable<ProfileDetailsDTO> {
    return this._http.post<any>(this.url + 'api/User/GetProfileDetails', {
      SessionId: sessionId,
      IsFlippingList: false
    }).pipe(
      map((response: any) => {
        return response as ProfileDetailsDTO;
      })
    );
  }
  activateAndSetSaleAlert(sessionId,minProfit,saleAlertActive): any {
    return this._http.post(this.url + 'api/User/SaveSalesAlert', {
      SessionId : sessionId,
      MinProfit : minProfit,
      SaleAlertActive : saleAlertActive
    })
  }
  deactivateAndSetSaleAlert(sessionId,journalItemId):any{
    return this._http.post(this.url + 'api/User/DeactivateSaleAlert', {
      SessionId : sessionId,
      journalItemId :journalItemId
    })
  }

}
