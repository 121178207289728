<div class="calculator_container" style="margin-top: 1em;">
  <div class="col-xs-6 col-sm-6  col-xs-offset-1">
    <form id="calculator">
      <fieldset>
          <legend>EA Tax calculator</legend>
        <div class="form-group">
          <label class="col-md-4">Sales Price</label>
          <!-- <input type="number" onkeypress="return event.charCode >= 48" min="1" class="form-control"  [(ngModel)] = "salePrice" name="sales_price" placeholder="Please enter your sales price" (input)="calculateTax($event.target.value)" /> -->
          <input type="text" min="1" class="form-control"  [(ngModel)] = "salePrice" name="sales_price" placeholder="Please enter your sales price" (change)="calculateTax($event.target.value)" (keyup)="calculateTax($event.target.value)"/>
        </div>
        <div class="form-group">
          <label class="col-md-4">EA Tax</label>
          <input type="text" class="form-control"  [(ngModel)] = "tax" name="sales_tax" placeholder="Tax applied to your sales" disabled/>
        </div>
        <div class="form-group">
          <label class="col-md-4">Income after Tax</label>
          <input type="text" class="form-control"  [(ngModel)] = "retunProfit" name="sales_income" placeholder="Your income after tax" disabled/>
        </div>           
        <div class="col-xs-4 col-sm-4  col-xs-offset-4 ">
            <div class="row">
                <div class="col-xs-12">
                    <img src= "/assets/icons/FUT_logo.png"  style="width: 7em;"/>
                </div>                
                <div style="clear: both;"></div>
            </div>        
        </div>
      </fieldset>
    </form>
  </div>

</div>