import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router,ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { CreateUserAccountforWebRequest } from '../../models/CreateUserAccountforWebRequestDTO';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {

  errorMessage: string;
  pageTitle = 'Forgot Password';
  PreferredPlatformId : number;
  PreferredPlatformTitle : string;
  IsApiCallProgress : boolean

  constructor(private authService: AuthService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.PreferredPlatformTitle = 'Select Platform';
    this.IsApiCallProgress = false;
  }
  cancel(): void {
      this.router.navigate(['welcome']);
  }

  async forgotpassword(signupForm: NgForm): Promise<void> {
      if (signupForm && signupForm.valid) {        
        this.IsApiCallProgress = true;
        this.errorMessage = '';

        const usernameorEmail = signupForm.form.value.usernameorEmail;
        await this.authService.forgotpassword(usernameorEmail);

        if(this.authService.errorMessage != null && this.authService.errorMessage != ''){
            this.errorMessage = this.authService.errorMessage;
        }
        // else if (this.authService.redirectUrl) {
        //     //this.router.navigateByUrl(this.authService.redirectUrl);
        //     this.errorMessage = 'email is sent to you.';
        // } else {
        //     //TODO: after login redirect to personalize page
        //     //this.router.navigate(['/playerjournals']);
        //     //this.router.navigate(['/products']);
        //     this.errorMessage = 'email is sent to you.';
        // }

        this.IsApiCallProgress = false;
    } else {
          this.errorMessage = 'Please enter a user name or email.';
      }
  }
}
