<!-- <div class="futbody">
    <router-outlet></router-outlet>
</div> -->
<!-- <div style="width:100px; height:100px;">
    <div style="position:relative; top:0px; left:0px; opacity:0.3;"><img src="/assets/images/bg-1.jpg" alt=" " /></div>
    <div style="position:relative; top:0px; left:0px; opacity:1;"> 
        <router-outlet></router-outlet>  
    </div>    
</div> -->
<!-- <div class="futbody"></div> -->

    <router-outlet></router-outlet>  

