import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tax-calculator',
  templateUrl: './tax-calculator.component.html',
  styleUrls: ['../../app.component.css']
})
export class TaxCalculatorComponent implements OnInit {
  amount:number;
  constructor() { }

  ngOnInit() {
  }
  roundBreakEvenAmount(amount: number): number {
    return Math.floor(amount + (amount * 0.05));
  }
  roundNetAmount(amount :number):number{
    return Math.floor(amount - (amount * 0.05));
  }
  roundTaxAmount(amount : number) : number{
    return Math.floor(amount * 0.05 );
  }
}
